.paypal-1.property-primary-2 {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 59px;
}

.paypal-1.property-variant {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2.18px;
  display: flex;
  flex-direction: column;
  gap: 10.9px;
  height: 26px;
  justify-content: center;
  padding: 6.54px 4.36px;
  position: relative;
  width: 59px;
}

.paypal-1 .g-wrapper {
  height: 7.24px;
  overflow: hidden;
  position: relative;
  width: 42.99px;
}

.paypal-1 .g {
  height: 7px;
  width: 43px;
}

.paypal-1 .g-2 {
  background-size: 100% 100%;
  height: 7px;
}

.paypal-1 .g-3 {
  background-image: url(../../../static/img/image-82.png);
  background-size: 100% 100%;
  height: 7px;
  left: 19px;
  position: relative;
  width: 7px;
}

.paypal-1 .property-1-1-variant-8 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-10 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-11 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-4 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-9 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-3 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-5 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-primary {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-6 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-variant-12 {
  background-image: url(../../../static/img/g14-1.png);
}

.paypal-1 .property-1-1-variant-7 {
  background-image: url(../../../static/img/g14-2.png);
}

.paypal-1 .property-1-1-default {
  background-image: url(../../../static/img/g14-2.png);
}
