.component-11 {
  width: 203px;
}

.component-11 .group-6 {
  height: 116px;
  position: relative;
  top: 83px;
  width: 205px;
}

.component-11 .overlap-group-6 {
  background-image: url(../../../static/img/group-12.png);
  background-size: 100% 100%;
  height: 116px;
  position: relative;
  width: 203px;
}

.component-11 .text-wrapper-6 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  left: 71px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  top: 45px;
  white-space: nowrap;
  width: 59px;
}

.component-11 .group-7 {
  height: 116px;
  left: 0;
  position: absolute;
  top: 87px;
  width: 205px;
}

.component-11 .group-8 {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 152px;
}

.component-11.property-1-2-frame-40245 {
  height: 199px;
}

.component-11.property-1-2-frame-40246 {
  height: 203px;
  position: relative;
}
