.paypal.property-primary {
  height: 26px;
  left: 0;
  position: absolute;
  top: 0;
  width: 59px;
}

.paypal.property {
  align-items: center;
  border: 1px solid;
  border-color: #ffffff;
  border-radius: 2.18px;
  display: flex;
  flex-direction: column;
  gap: 10.9px;
  height: 26px;
  justify-content: center;
  padding: 6.54px 4.36px;
  position: relative;
  width: 59px;
}

.paypal .discover-card-logo {
  height: 8px;
  position: relative;
  width: 43px;
}

.paypal .ellipse-wrapper {
  background-size: 100% 100%;
  height: 7px;
}

.paypal .ellipse {
  border-radius: 3.5px;
  height: 7px;
  left: 19px;
  position: relative;
  top: 1px;
  width: 7px;
}

.paypal.variant-12 {
  background-color: #ffffff;
}

.property.variant-8 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-10 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-11 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-4 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-9 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-3 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-5 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.primary .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-6 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-12 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-3.png);
}

.property.variant-7 .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.default .ellipse-wrapper {
  background-image: url(../../../static/img/g14-4.png);
}

.property.variant-11 .ellipse {
  background-color: #d9d9d9;
}

.property.variant-12 .ellipse {
  background: linear-gradient(180deg, rgb(160, 42, 31) 0%, rgb(244, 133, 20) 100%);
}
