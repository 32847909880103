:root {
  --black-white-white: rgba(255, 255, 255, 1);
  --body-points-16-font-family: "Inter-Regular", Helvetica;
  --body-points-16-font-size: 16px;
  --body-points-16-font-style: normal;
  --body-points-16-font-weight: 400;
  --body-points-16-letter-spacing: -0.48px;
  --body-points-16-line-height: 138.99999856948853%;
  --border: rgba(32, 72, 79, 0.1);
  --core-colorswhite: rgba(255, 255, 255, 1);
  --dark: rgba(7, 33, 37, 1);
  --gray-100: rgba(207, 211, 214, 1);
  --graywhite: rgba(255, 255, 255, 1);
  --h2-subtext-font-family: "Manrope-Bold", Helvetica;
  --h2-subtext-font-size: 26px;
  --h2-subtext-font-style: normal;
  --h2-subtext-font-weight: 700;
  --h2-subtext-letter-spacing: -0.78px;
  --h2-subtext-line-height: 138.99999856948853%;
  --h3-banner-text-font-family: "Inter-Bold", Helvetica;
  --h3-banner-text-font-size: 20px;
  --h3-banner-text-font-style: normal;
  --h3-banner-text-font-weight: 700;
  --h3-banner-text-letter-spacing: 0px;
  --h3-banner-text-line-height: normal;
  --header: rgba(34, 34, 34, 1);
  --light-background-color: rgba(255, 255, 255, 1);
  --neutral-60: rgba(146, 145, 145, 1);
  --neutral-80: rgba(190, 189, 189, 1);
  --neutrals-2: rgba(245, 245, 245, 1);
  --primary: rgba(255, 255, 255, 1);
  --primary-color: rgba(253, 70, 74, 1);
  --secondary: rgba(14, 59, 101, 1);
  --white: rgba(255, 255, 255, 1);
  --x-383838: rgba(56, 56, 56, 1);
}
