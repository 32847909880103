.component {
  width: 198px;
}

.component .group {
  position: relative;
  width: 200px;
}

.component .overlap-group {
  background-image: url(../../../static/img/group-6.png);
  background-size: 100% 100%;
  height: 128px;
  position: relative;
  width: 198px;
}

.component .text-wrapper {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 17px;
  left: 69px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  top: 43px;
  white-space: nowrap;
  width: 65px;
}

.component .new-job {
  height: 21px;
  left: 86px;
  position: absolute;
  top: 18px;
  width: 21px;
}

.component .overlap-group-wrapper {
  height: 128px;
  left: 0;
  position: absolute;
  top: 71px;
  width: 200px;
}

.component .group-wrapper {
  height: 77px;
  left: 23px;
  position: absolute;
  top: 0;
  width: 148px;
}

.component .overlap-wrapper {
  box-shadow: -2px 0px 6px #00000012;
  height: 77px;
  width: 150px;
}

.component .overlap {
  height: 77px;
  position: relative;
  width: 148px;
}

.component .div {
  height: 77px;
  left: 0;
  position: absolute;
  top: 0;
  width: 148px;
}

.component .overlap-group-2 {
  height: 77px;
  position: relative;
}

.component .rectangle {
  background-color: #ffffff;
  border-radius: 8px;
  height: 72px;
  left: 0;
  position: absolute;
  top: 0;
  width: 148px;
}

.component .vector {
  height: 6px;
  left: 70px;
  position: absolute;
  top: 71px;
  width: 12px;
}

.component .text-wrapper-2 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 12px;
  font-weight: 400;
  height: 17px;
  left: 13px;
  letter-spacing: 0;
  line-height: 16.8px;
  position: absolute;
  text-align: center;
  top: 28px;
  width: 124px;
}

.component.frame-40245 {
  height: 188px;
}

.component.frame-40246 {
  height: 213px;
}

.component.frame-40245 .group {
  height: 128px;
  top: 60px;
}

.component.frame-40246 .group {
  height: 199px;
  top: 14px;
}
