.property-group-wrapper .overlap-group-3 {
  background-image: url(../../../static/img/group-9.png);
  background-size: 100% 100%;
  height: 183px;
  position: relative;
  width: 158px;
}

.property-group-wrapper .text-wrapper-3 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  left: 49px;
  letter-spacing: 0;
  line-height: 22.4px;
  opacity: 0.79;
  position: absolute;
  top: 71px;
  white-space: nowrap;
  width: 50px;
}

.property-group-wrapper .cash {
  height: 23px;
  left: 63px;
  position: absolute;
  top: 44px;
  width: 23px;
}

.property-group-wrapper .group-2 {
  height: 183px;
  left: 13px;
  position: absolute;
  top: 40px;
  width: 160px;
}

.property-group-wrapper .overlap-group-4 {
  background-image: url(../../../static/img/group-8.png);
  background-size: 100% 100%;
  height: 183px;
  position: relative;
  width: 158px;
}

.property-group-wrapper .text-wrapper-4 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  left: 49px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  top: 71px;
  white-space: nowrap;
  width: 50px;
}

.property-group-wrapper .img {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 152px;
}

.property-group-wrapper.group-237575 {
  height: 183px;
  width: 158px;
}

.property-group-wrapper.group-237578 {
  height: 224px;
  width: 171px;
}

.property-group-wrapper.group-237575 .group-54 {
  height: 183px;
  width: 160px;
}

.property-group-wrapper.group-237578 .group-54 {
  height: 224px;
  position: relative;
  width: 173px;
}
