.div-wrapper {
  height: 367px;
  width: 454px;
}

.div-wrapper .overlap-group-7 {
  height: 380px;
  position: relative;
  width: 463px;
}

.div-wrapper .rectangle-2 {
  height: 313px;
  left: 221px;
  position: absolute;
  top: 31px;
  width: 242px;
}

.div-wrapper .rectangle-3 {
  height: 313px;
  left: 0;
  position: absolute;
  top: 31px;
  width: 233px;
}

.div-wrapper .rectangle-4 {
  height: 380px;
  left: 76px;
  position: absolute;
  top: 0;
  width: 296px;
}
