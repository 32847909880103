.skillbook-case-study {
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

body{
  overflow-x: hidden;
}

.skillbook-case-study .div-2 {
  background-color: #ffffff;
  height: 14889px;
  /* overflow: hidden; */
  position: relative;
  width: 1920px;
}

.skillbook-case-study .about {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 96px 120px;
  position: absolute;
  top: 1779px;
  width: 1920px;
}

.skillbook-case-study .frame-11 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 892px;
}

.skillbook-case-study .frame-12 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 48px;
  position: relative;
  width: 183px;
}

.skillbook-case-study .frame-13 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.skillbook-case-study .text-wrapper-9 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: -0.17px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .skillbook-academy-an-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 64px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .skillbook-academy-an {
  color: #676768;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  width: 858px;
}

.skillbook-case-study .frame-14 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  position: relative;
}

.skillbook-case-study .text-wrapper-10 {
  color: #6b6b6b;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 40px;
  margin-top: -1px;
  position: relative;
  width: 239px;
}

.skillbook-case-study .text-wrapper-11 {
  color: #6b6b6b;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.12px;
  line-height: 40px;
  position: relative;
  width: 239px;
}

.skillbook-case-study .mockup {
  height: 1187px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 592px;
  width: 1920px;
}

.skillbook-case-study .overlap-2 {
  height: 6427px;
  position: relative;
  top: -61px;
}

.skillbook-case-study .rectangle-5 {
  background-color: #fb5741;
  height: 1199px;
  left: 0;
  position: absolute;
  top: 61px;
  width: 1920px;
}

.skillbook-case-study .edited-final-landing {
  background-color: #ffffff;
  height: 6427px;
  left: 841px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 969px;
}

.skillbook-case-study .frame-15 {
  align-items: center;
  display: inline-flex;
  gap: 5.38px;
  left: 54px;
  position: absolute;
  top: 67px;
}

.skillbook-case-study .text-wrapper-12 {
  color: #d2d2d2;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 11.2px;
  position: relative;
  width: 22.88px;
}

.skillbook-case-study .back {
  height: 4.04px;
  position: relative;
  width: 4.04px;
}

.skillbook-case-study .text-wrapper-13 {
  color: #d2d2d2;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 11.2px;
  position: relative;
  width: 30.28px;
}

.skillbook-case-study .text-wrapper-14 {
  color: #9a9a9a;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 700;
  letter-spacing: -0.28px;
  line-height: 13.1px;
  margin-top: -0.67px;
  position: relative;
  width: 29.61px;
}

.skillbook-case-study .frame-16 {
  align-items: flex-start;
  display: inline-flex;
  gap: 21.54px;
  left: 54px;
  position: absolute;
  top: 106px;
}

.skillbook-case-study .frame-17 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  height: 318.32px;
  position: relative;
}

.skillbook-case-study .frame-18 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.77px;
  position: relative;
}

.skillbook-case-study .certified-wrapper {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 13.46px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .certified {
  color: var(--header);
  font-family: "Manrope", Helvetica;
  font-size: 24.2px;
  font-weight: 500;
  letter-spacing: -0.73px;
  line-height: 33.7px;
  margin-top: -0.67px;
  position: relative;
  width: 366.1px;
}

.skillbook-case-study .frame-19 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  position: relative;
  width: 503.39px;
}

.skillbook-case-study .text-wrapper-15 {
  align-self: stretch;
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  margin-top: -0.67px;
  position: relative;
}

.skillbook-case-study .div-3 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.08px;
  position: relative;
}

.skillbook-case-study .frame-20 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 129.89px;
  position: relative;
}

.skillbook-case-study .frame-21 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.08px;
  position: relative;
}

.skillbook-case-study .thumbs-up {
  height: 11.44px;
  position: relative;
  width: 11.44px;
}

.skillbook-case-study .text-wrapper-16 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: -0.3px;
  line-height: 14px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .student-registration {
  height: 12.11px;
  position: relative;
  width: 12.11px;
}

.skillbook-case-study .audio {
  height: 12.79px;
  position: relative;
  width: 12.79px;
}

.skillbook-case-study .frame-22 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 43.07px;
  position: relative;
}

.skillbook-case-study .frame-23 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16.15px;
  position: relative;
}

.skillbook-case-study .group-9 {
  align-self: stretch;
  position: relative;
  width: 99.51px;
}

.skillbook-case-study .line {
  height: 27.1px;
  position: relative;
  width: 1px;
}

.skillbook-case-study .group-10 {
  height: 47.73px;
  position: relative;
  width: 100.02px;
}

.skillbook-case-study .content-sai {
  height: 39.03px;
  object-fit: cover;
  position: relative;
  width: 130.35px;
}

.skillbook-case-study .element-sa-badge {
  height: 67.3px;
  object-fit: cover;
  position: relative;
  width: 68.25px;
}

.skillbook-case-study .frame-24 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  position: relative;
}

.skillbook-case-study .component-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 11.44px;
  position: relative;
}

.skillbook-case-study .component-3 {
  background-image: url(../../../static/img/rectangle-47.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 13.46px;
  height: 160.84px;
  overflow: hidden;
  position: relative;
  width: 263.14px;
}

.skillbook-case-study .play-circle-filled {
  height: 46px;
  left: 112px;
  position: absolute;
  top: 57px;
  width: 46px;
}

.skillbook-case-study .csm-certified {
  height: 35px;
  left: 212px;
  position: absolute;
  top: 8px;
  width: 43px;
}

.skillbook-case-study .best-seller-wrapper {
  align-items: center;
  background-color: #eec55e;
  border-radius: 2.69px;
  display: inline-flex;
  gap: 6.73px;
  left: 11px;
  overflow: hidden;
  padding: 4.04px;
  position: absolute;
  top: 11px;
}

.skillbook-case-study .best-seller {
  color: #222222;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 500;
  letter-spacing: 0.04px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-25 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.75px;
  position: relative;
}

.skillbook-case-study .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.08px;
  position: relative;
}

.skillbook-case-study .frame-27 {
  align-items: center;
  background-color: #ebebeb;
  border-radius: 2.69px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.04px;
  justify-content: center;
  padding: 1.35px 4.04px;
  position: relative;
}

.skillbook-case-study .text-wrapper-17 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 15px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-28 {
  align-items: center;
  background-color: #ebebeb;
  border-radius: 2.69px;
  display: flex;
  gap: 3.36px;
  padding: 1.35px 6.06px;
  position: relative;
  width: 72.01px;
}

.skillbook-case-study .frame-29 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.77px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7.4px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .frame-31 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.38px;
  position: relative;
}

.skillbook-case-study .frame-32 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.skillbook-case-study .starting-from {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 15px;
  margin-top: -0.67px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .span {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 15px;
}

.skillbook-case-study .text-wrapper-18 {
  font-size: 16.2px;
  letter-spacing: -0.48px;
  line-height: 22.5px;
}

.skillbook-case-study .text-wrapper-19 {
  font-size: 13.5px;
  font-weight: 700;
  letter-spacing: -0.4px;
  line-height: 18.7px;
  text-decoration: line-through;
}

.skillbook-case-study .text-wrapper-20 {
  font-size: 16.2px;
  font-weight: 700;
  letter-spacing: -0.48px;
  line-height: 22.5px;
}

.skillbook-case-study .element-2 {
  height: 20.19px;
  position: relative;
  width: 55.18px;
}

.skillbook-case-study .frame-33 {
  align-items: center;
  align-self: stretch;
  background-color: #f4fdf8;
  border-radius: 5.38px;
  box-shadow: 0px 2.69px 10.77px #0000000f;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  justify-content: center;
  padding: 10.77px 4.04px 10.77px 5.38px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-34 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8.08px;
  position: relative;
}

.skillbook-case-study .early-bird-tickets {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 400;
  letter-spacing: -0.28px;
  line-height: 13.1px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-21 {
  color: #383838;
  font-weight: 500;
}

.skillbook-case-study .text-wrapper-22 {
  color: #fb5741;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: -0.3px;
  line-height: 14px;
}

.skillbook-case-study .frame-35 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .frame-36 {
  align-items: center;
  background-color: #fb5741;
  border-radius: 5.38px;
  display: flex;
  gap: 6.73px;
  height: 30.96px;
  justify-content: center;
  padding: 10.77px 59.22px 10.77px 56.53px;
  position: relative;
  width: 115.08px;
}

.skillbook-case-study .text-wrapper-23 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -0.44px;
  margin-left: -46.34px;
  margin-right: -46.34px;
  margin-top: -3.14px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-37 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 16.15px;
  left: 54px;
  position: absolute;
  top: 456px;
}

.skillbook-case-study .group-11 {
  height: 22.88px;
  position: relative;
  width: 551.85px;
}

.skillbook-case-study .overlap-group-8 {
  height: 23px;
  position: relative;
  width: 552px;
}

.skillbook-case-study .group-12 {
  height: 23px;
  left: 1px;
  position: absolute;
  top: 0;
  width: 551px;
}

.skillbook-case-study .frame-38 {
  align-items: center;
  display: flex;
  gap: 17.5px;
  height: 17px;
  left: 0;
  position: absolute;
  top: 0;
  width: 551px;
}

.skillbook-case-study .text-wrapper-24 {
  color: #fb5741;
  font-family: "Inter", Helvetica;
  font-size: 12.1px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-25 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
  width: 74.03px;
}

.skillbook-case-study .text-wrapper-26 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 12.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .line-2 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 550px;
}

.skillbook-case-study .rectangle-6 {
  background-color: #fb5741;
  height: 1px;
  left: 0;
  position: absolute;
  top: 22px;
  width: 61px;
}

.skillbook-case-study .text-wrapper-27 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: -0.52px;
  line-height: 24.3px;
  margin-top: -0.67px;
  position: relative;
  width: 424.65px;
}

.skillbook-case-study .text-wrapper-28 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: relative;
  width: 551.85px;
}

.skillbook-case-study .main-container {
  background-color: #000000;
  border: 0.67px solid;
  border-color: #cacaca;
  border-radius: 10.77px;
  box-shadow: 0px 1.35px 28.94px #7272721c;
  height: 114px;
  left: 41px;
  overflow: hidden;
  position: absolute;
  top: 617px;
  width: 870px;
}

.skillbook-case-study .frame-39 {
  align-items: center;
  display: inline-flex;
  gap: 16.15px;
  left: 215px;
  position: relative;
  top: 25px;
}

.skillbook-case-study .frame-40 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 9.42px;
  position: relative;
}

.skillbook-case-study .group-13 {
  height: 39.32px;
  position: relative;
  width: 33.65px;
}

.skillbook-case-study .text-wrapper-29 {
  color: #ffedd9;
  font-family: "Manrope", Helvetica;
  font-size: 43.1px;
  font-weight: 700;
  letter-spacing: -2.15px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-41 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 3.36px;
  position: relative;
  width: 292.75px;
}

.skillbook-case-study .highest-pass-rate-in {
  color: transparent;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -53.17px;
  margin-top: -0.67px;
  position: relative;
  width: 345.91px;
}

.skillbook-case-study .text-wrapper-30 {
  color: #fb5741;
}

.skillbook-case-study .text-wrapper-31 {
  color: #ffffff;
}

.skillbook-case-study .if-you-are-not-able {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  margin-right: -2.02px;
  position: relative;
  width: 294.77px;
}

.skillbook-case-study .group-14 {
  background-color: #fcfcfc;
  box-shadow: 0px 2.69px 6.06px #0000000a;
  height: 56px;
  left: 0;
  position: absolute;
  top: -3px;
  width: 969px;
}

.skillbook-case-study .frame-42 {
  align-items: center;
  display: flex;
  gap: 333.8px;
  left: 59px;
  position: relative;
  top: 16px;
  width: 857px;
}

.skillbook-case-study .frame-43 {
  flex: 0 0 auto;
  margin-bottom: -9832.54px;
  position: relative;
}

.skillbook-case-study .frame-44 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24.23px;
  position: relative;
}

.skillbook-case-study .text-wrapper-32 {
  color: #222222;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: -0.32px;
  line-height: 15px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-45 {
  height: 5665px;
  left: 0;
  position: absolute;
  top: 762px;
  width: 969px;
}

.skillbook-case-study .frame-46 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 0;
}

.skillbook-case-study .frame-47 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 1.35px;
  position: relative;
}

.skillbook-case-study .tabs {
  background-color: #fff9f1;
  height: 252.37px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .overlap-3 {
  height: 224px;
  left: 62px;
  position: relative;
  top: 22px;
  width: 823px;
}

.skillbook-case-study .text-wrapper-33 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 800;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 14px;
  width: 170px;
}

.skillbook-case-study .overlap-4 {
  height: 224px;
  left: 0;
  position: absolute;
  top: 0;
  width: 823px;
}

.skillbook-case-study .frame-48 {
  align-items: center;
  display: inline-flex;
  gap: 8.75px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 62px;
}

.skillbook-case-study .frame-49 {
  background-color: #4f7caa;
  border-radius: 4.4px;
  height: 16.15px;
  position: relative;
  width: 16.15px;
}

.skillbook-case-study .div-4 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
  position: relative;
  width: 358.7px;
}

.skillbook-case-study .text-wrapper-34 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
}

.skillbook-case-study .text-wrapper-35 {
  font-weight: 700;
}

.skillbook-case-study .frame-50 {
  align-items: center;
  display: inline-flex;
  gap: 8.75px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 98px;
}

.skillbook-case-study .check-wrapper {
  background-color: #14a0c1;
  border-radius: 4.4px;
  height: 16.15px;
  position: relative;
  width: 16.15px;
}

.skillbook-case-study .check {
  height: 9px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 9px;
}

.skillbook-case-study .frame-51 {
  align-items: center;
  display: inline-flex;
  gap: 8.75px;
  justify-content: center;
  left: 203px;
  position: absolute;
  top: 61px;
}

.skillbook-case-study .frame-52 {
  background-color: #00cd99;
  border-radius: 4.4px;
  height: 16.15px;
  position: relative;
  width: 16.15px;
}

.skillbook-case-study .frame-53 {
  align-items: center;
  display: inline-flex;
  gap: 8.75px;
  justify-content: center;
  left: 203px;
  position: absolute;
  top: 96px;
}

.skillbook-case-study .frame-54 {
  background-color: #fb5741;
  border-radius: 4.4px;
  height: 16.15px;
  position: relative;
  width: 16.15px;
}

.skillbook-case-study .frame-55 {
  align-items: center;
  display: inline-flex;
  gap: 8.75px;
  justify-content: center;
  left: 203px;
  position: absolute;
  top: 133px;
}

.skillbook-case-study .frame-56 {
  height: 224px;
  left: 582px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 241px;
}

.skillbook-case-study .overlap-5 {
  height: 234px;
  left: 0;
  position: absolute;
  top: -22px;
  width: 241px;
}

.skillbook-case-study .component-10 {
  height: 127px !important;
  left: 87px !important;
  position: absolute !important;
  top: 0 !important;
  width: 133px !important;
}

.skillbook-case-study .component-instance {
  height: 86px !important;
  top: 40px !important;
  width: 135px !important;
}

.skillbook-case-study .component-10-instance {
  background-image: url(../../../static/img/group-5.png) !important;
  height: 86px !important;
  width: 133px !important;
}

.skillbook-case-study .design-component-instance-node {
  font-size: 10.8px !important;
  height: 11px !important;
  left: 47px !important;
  line-height: 15.1px !important;
  top: 29px !important;
  width: 44px !important;
}

.skillbook-case-study .component-4 {
  height: 14px !important;
  left: 58px !important;
  top: 12px !important;
  width: 14px !important;
}

.skillbook-case-study .vector-3 {
  height: 31px;
  left: 51px;
  position: absolute;
  top: 138px;
  width: 1px;
}

.skillbook-case-study .component-9 {
  height: 123px !important;
  left: 0 !important;
  position: absolute !important;
  top: 22px !important;
  width: 107px !important;
}

.skillbook-case-study .component-9-instance {
  height: 123px !important;
  width: 109px !important;
}

.skillbook-case-study .component-5 {
  background-image: url(../../../static/img/group-4.png) !important;
  height: 123px !important;
  width: 107px !important;
}

.skillbook-case-study .component-6 {
  font-size: 10.8px !important;
  height: 11px !important;
  left: 33px !important;
  line-height: 15.1px !important;
  top: 48px !important;
  width: 34px !important;
}

.skillbook-case-study .component-7 {
  height: 15px !important;
  left: 42px !important;
  top: 30px !important;
  width: 15px !important;
}

.skillbook-case-study .group-15 {
  height: 109px;
  left: 164px;
  position: absolute;
  top: 94px;
  width: 77px;
}

.skillbook-case-study .text-wrapper-36 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 700;
  height: 11px;
  left: 127px;
  letter-spacing: 0;
  line-height: 15.1px;
  position: absolute;
  top: 186px;
  white-space: nowrap;
  width: 40px;
}

.skillbook-case-study .top-companies {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 700;
  height: 22px;
  left: 179px;
  letter-spacing: 0;
  line-height: 11.6px;
  position: absolute;
  text-align: center;
  top: 127px;
  width: 58px;
}

.skillbook-case-study .component-12 {
  height: 131px !important;
  left: 0 !important;
  position: absolute !important;
  top: 96px !important;
  width: 102px !important;
}

.skillbook-case-study .component-12-instance {
  height: 115px !important;
  top: 16px !important;
  width: 104px !important;
}

.skillbook-case-study .component-8 {
  background-image: url(../../../static/img/group-2.png) !important;
  height: 115px !important;
  width: 102px !important;
}

.skillbook-case-study .component-13 {
  font-size: 10.8px !important;
  height: 11px !important;
  left: 39px !important;
  line-height: 15.1px !important;
  top: 41px !important;
  width: 26px !important;
}

.skillbook-case-study .component-14 {
  height: 16px !important;
  left: 39px !important;
  top: 20px !important;
  width: 16px !important;
}

.skillbook-case-study .component-11-instance {
  height: 134px !important;
  left: 79px !important;
  position: absolute !important;
  top: 100px !important;
  width: 137px !important;
}

.skillbook-case-study .component-15 {
  height: 78px !important;
  top: 56px !important;
  width: 138px !important;
}

.skillbook-case-study .component-16 {
  background-image: url(../../../static/img/group-1.png) !important;
  height: 78px !important;
  width: 136px !important;
}

.skillbook-case-study .component-17 {
  font-size: 10.8px !important;
  height: 11px !important;
  left: 48px !important;
  line-height: 15.1px !important;
  top: 30px !important;
  width: 40px !important;
}

.skillbook-case-study .receive-cash {
  height: 9px;
  left: 186px;
  position: absolute;
  top: 215px;
  width: 32px;
}

.skillbook-case-study .group-of-companies {
  height: 33px;
  left: 104px;
  position: absolute;
  top: 9191px;
  width: 33px;
}

.skillbook-case-study .rectangle-7 {
  background-color: #5059ac;
  border-radius: 4.4px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 133px;
  width: 16px;
}

.skillbook-case-study .text-wrapper-37 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  height: 14px;
  left: 25px;
  letter-spacing: 0;
  line-height: 14.1px;
  position: absolute;
  top: 134px;
  width: 77px;
}

.skillbook-case-study .text-wrapper-38 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  height: 28px;
  left: 25px;
  letter-spacing: 0;
  line-height: 14.1px;
  position: absolute;
  top: 151px;
  width: 150px;
}

.skillbook-case-study .text-wrapper-39 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  height: 28px;
  left: 229px;
  letter-spacing: 0;
  line-height: 14.1px;
  position: absolute;
  top: 151px;
  width: 150px;
}

.skillbook-case-study .frame-57 {
  align-items: flex-start;
  background-color: #ffffff;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.92px 55.18px;
  position: relative;
}

.skillbook-case-study .frame-58 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 121.14px;
  position: relative;
  width: 858.73px;
}

.skillbook-case-study .frame-59 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  position: relative;
}

.skillbook-case-study .tab {
  align-items: flex-start;
  background-color: #1c466d;
  border: 0.67px solid;
  border-color: var(--border);
  border-radius: 5.38px;
  display: flex;
  gap: 6.73px;
  height: 30.96px;
  padding: 8.75px 24.23px 8.75px 10.77px;
  position: relative;
  width: 144.69px;
}

.skillbook-case-study .text-wrapper-40 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-right: -12.3px;
  margin-top: -0.67px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .tab-2 {
  align-items: center;
  background-color: #f6f6f6;
  border: 0.67px solid;
  border-color: var(--border);
  border-radius: 5.38px;
  display: flex;
  flex: 0 0 auto;
  gap: 6.73px;
  padding: 8.75px 24.23px 8.75px 13.46px;
  position: relative;
  width: 144.69px;
}

.skillbook-case-study .text-wrapper-41 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .group-16 {
  height: 141.2px;
  position: relative;
  width: 470.42px;
}

.skillbook-case-study .frame-60 {
  align-items: center;
  display: inline-flex;
  gap: 19.52px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
}

.skillbook-case-study .group-17 {
  height: 23.55px;
  margin-left: -0.33px;
  position: relative;
  width: 23.55px;
}

.skillbook-case-study .frame-61 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24.9px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .frame-62 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.06px;
  position: relative;
}

.skillbook-case-study .text-wrapper-42 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 147.38px;
}

.skillbook-case-study .frame-63 {
  align-items: center;
  display: flex;
  gap: 15.48px;
  left: 252px;
  position: absolute;
  top: 1px;
  width: 219px;
}

.skillbook-case-study .group-18 {
  height: 22.88px;
  position: relative;
  width: 22.88px;
}

.skillbook-case-study .frame-64 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20.19px;
  justify-content: center;
  margin-right: -57.88px;
  position: relative;
}

.skillbook-case-study .frame-65 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6.06px;
  position: relative;
  width: 238.24px;
}

.skillbook-case-study .text-wrapper-43 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-right: -17.5px;
  margin-top: -0.67px;
  position: relative;
  width: 255.73px;
}

.skillbook-case-study .frame-66 {
  align-items: center;
  display: inline-flex;
  gap: 22.21px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 59px;
}

.skillbook-case-study .vector-4 {
  height: 25.57px;
  position: relative;
  width: 22.21px;
}

.skillbook-case-study .frame-67 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.38px;
  position: relative;
}

.skillbook-case-study .text-wrapper-44 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 152.09px;
}

.skillbook-case-study .frame-68 {
  align-items: center;
  display: inline-flex;
  gap: 20.19px;
  justify-content: center;
  left: 252px;
  position: absolute;
  top: 59px;
}

.skillbook-case-study .group-19 {
  height: 25.46px;
  position: relative;
  width: 20.19px;
}

.skillbook-case-study .frame-69 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20.86px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .ensure-effective {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 125.18px;
}

.skillbook-case-study .frame-70 {
  align-items: center;
  display: inline-flex;
  gap: 24.9px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 119px;
}

.skillbook-case-study .group-20 {
  height: 22.08px;
  position: relative;
  width: 18.84px;
}

.skillbook-case-study .text-wrapper-45 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 179.01px;
}

.skillbook-case-study .frame-71 {
  align-items: center;
  display: flex;
  gap: 20.19px;
  left: 252px;
  position: absolute;
  top: 117px;
  width: 216px;
}

.skillbook-case-study .vector-5 {
  height: 20.86px;
  position: relative;
  width: 23.55px;
}

.skillbook-case-study .frame-72 {
  align-items: center;
  display: flex;
  gap: 19.52px;
  margin-right: -115.75px;
  position: relative;
  width: 288.04px;
}

.skillbook-case-study .ease-the-adoption-of-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4.04px;
  position: relative;
}

.skillbook-case-study .ease-the-adoption-of {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 156.13px;
}

.skillbook-case-study .frame-73 {
  align-items: flex-start;
  background-color: #f4fdf8;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.92px 53.84px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-74 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21.54px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .frame-75 {
  height: 88.16px;
  position: relative;
  width: 858.73px;
}

.skillbook-case-study .frame-76 {
  align-items: center;
  display: inline-flex;
  gap: 58.55px;
  position: relative;
}

.skillbook-case-study .frame-77 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10.77px;
  position: relative;
  width: 366.78px;
}

.skillbook-case-study .text-wrapper-46 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  height: 35px;
  letter-spacing: -0.44px;
  line-height: 67.8px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: 63.26px;
}

.skillbook-case-study .become-an-instructor {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: relative;
  width: 357.35px;
}

.skillbook-case-study .frame-78 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 21.54px;
  position: relative;
}

.skillbook-case-study .frame-79 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.38px;
  position: relative;
  width: 80.09px;
}

.skillbook-case-study .text-wrapper-47 {
  color: #fb5741;
  font-family: "Manrope", Helvetica;
  font-size: 24.2px;
  font-weight: 800;
  letter-spacing: -0.73px;
  line-height: 33.7px;
  margin-right: -10.77px;
  margin-top: -0.67px;
  position: relative;
  width: 90.85px;
}

.skillbook-case-study .text-wrapper-48 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-49 {
  color: #fb5741;
  font-family: "Manrope", Helvetica;
  font-size: 24.2px;
  font-weight: 800;
  letter-spacing: -0.73px;
  line-height: 33.7px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-50 {
  color: #fb5741;
  font-family: "Manrope", Helvetica;
  font-size: 24.2px;
  font-weight: 800;
  letter-spacing: -0.73px;
  line-height: 33.7px;
  margin-top: -0.67px;
  position: relative;
  width: 57.2px;
}

.skillbook-case-study .text-wrapper-51 {
  color: #fb5741;
  font-family: "Manrope", Helvetica;
  font-size: 24.2px;
  font-weight: 800;
  letter-spacing: -0.73px;
  line-height: 33.7px;
  margin-top: -0.67px;
  position: relative;
  width: 119.79px;
}

.skillbook-case-study .frame-80 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 16.15px;
  overflow-x: scroll;
  position: relative;
  width: 860.75px;
}

.skillbook-case-study .frame-81 {
  align-items: flex-start;
  background-color: #000000;
  border-radius: 10.77px;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  padding: 21.54px;
  position: relative;
  width: 423.31px;
}

.skillbook-case-study .frame-82 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-83 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.77px;
  position: relative;
}

.skillbook-case-study .rectangle-8 {
  height: 44.42px;
  position: relative;
  width: 52.49px;
}

.skillbook-case-study .text-wrapper-52 {
  color: #eaeaea;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: -0.52px;
  line-height: 24.3px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-84 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.4px;
  padding: 2.69px;
  position: relative;
}

.skillbook-case-study .google-g-logo {
  height: 9.42px;
  object-fit: cover;
  position: relative;
  width: 9.42px;
}

.skillbook-case-study .frame-85 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 1.35px;
  position: relative;
}

.skillbook-case-study .star {
  height: 10.09px;
  position: relative;
  width: 10.09px;
}

.skillbook-case-study .text-wrapper-53 {
  color: #f6f6f6;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: 0.04px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-54 {
  align-self: stretch;
  color: #eaeaea;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
}

.skillbook-case-study .frame-86 {
  align-items: flex-start;
  background-color: #000000;
  border-radius: 10.77px;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  padding: 21.54px;
  position: relative;
  width: 421.29px;
}

.skillbook-case-study .frame-87 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  margin-right: -1.35px;
  position: relative;
}

.skillbook-case-study .text-wrapper-55 {
  color: #eaeaea;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 379.56px;
}

.skillbook-case-study .frame-88 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12.79px;
  position: relative;
}

.skillbook-case-study .frame-89 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 341.2px;
  position: relative;
}

.skillbook-case-study .ellipse-2 {
  background-color: #000000;
  border-radius: 4.04px;
  height: 8.08px;
  position: relative;
  width: 8.08px;
}

.skillbook-case-study .ellipse-3 {
  background-color: var(--gray-100);
  border-radius: 4.04px;
  height: 8.08px;
  position: relative;
  width: 8.08px;
}

.skillbook-case-study .frame-90 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 5.38px;
  position: relative;
}

.skillbook-case-study .caret-left-wrapper {
  align-items: center;
  background-color: #ffffff;
  border: 0.67px solid;
  border-color: #cccccc;
  border-radius: 5.38px;
  box-shadow: -2.69px 2.69px 13.46px #20b4861f;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.73px;
  height: 18.84px;
  justify-content: center;
  padding: 6.73px;
  position: relative;
}

.skillbook-case-study .caret-right-wrapper {
  align-items: center;
  background-color: #000000;
  border-radius: 5.38px;
  box-shadow: -2.69px 2.69px 13.46px #20b4861f;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.73px;
  height: 18.84px;
  justify-content: center;
  padding: 6.73px;
  position: relative;
}

.skillbook-case-study .frame-91 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7.23px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .text-wrapper-56 {
  color: #222222;
  font-family: "Inter", Helvetica;
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.9px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-92 {
  align-items: flex-start;
  background-color: #ffffff;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.92px 53.84px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-93 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 97.58px;
  position: relative;
}

.skillbook-case-study .frame-94 {
  height: 153.44px;
  position: relative;
  width: 343.89px;
}

.skillbook-case-study .group-21 {
  height: 322px;
  left: -63px;
  position: relative;
  top: -44px;
  width: 469px;
}

.skillbook-case-study .overlap-group-9 {
  background-image: url(../../../static/img/vector-6.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 322px;
  left: 417px;
  position: relative;
  top: 8213px;
}

.skillbook-case-study .overlap-6 {
  height: 161px;
  left: 63px;
  position: absolute;
  top: 127px;
  width: 182px;
}

.skillbook-case-study .sign-up {
  height: 8px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 39px;
}

.skillbook-case-study .overlap-7 {
  height: 161px;
  left: 0;
  position: absolute;
  top: 0;
  width: 182px;
}

.skillbook-case-study .vector-6 {
  height: 101px;
  left: 0;
  position: absolute;
  top: 60px;
  width: 96px;
}

.skillbook-case-study .vector-7 {
  height: 101px;
  left: 86px;
  position: absolute;
  top: 0;
  width: 96px;
}

.skillbook-case-study .overlap-8 {
  height: 161px;
  left: 244px;
  position: absolute;
  top: 127px;
  width: 190px;
}

.skillbook-case-study .vector-8 {
  height: 101px;
  left: 0;
  position: absolute;
  top: 59px;
  width: 96px;
}

.skillbook-case-study .vector-9 {
  height: 101px;
  left: 86px;
  position: absolute;
  top: 0;
  width: 105px;
}

.skillbook-case-study .text-wrapper-57 {
  color: #fb5741;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .steps-to-become-a {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 400;
  letter-spacing: 0.09px;
  line-height: 17.6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-58 {
  font-weight: 800;
}

.skillbook-case-study .frame-95 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.79px;
  position: relative;
  width: 298.13px;
}

.skillbook-case-study .frame-96 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.75px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .text-wrapper-59 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.7px;
  font-weight: 500;
  height: 12px;
  left: 6px;
  letter-spacing: 0;
  line-height: 12.2px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.skillbook-case-study .sign-up-for-a-CSM {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
  position: relative;
  width: 257.75px;
}

.skillbook-case-study .text-wrapper-60 {
  font-weight: 600;
}

.skillbook-case-study .text-wrapper-61 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.7px;
  font-weight: 500;
  height: 12px;
  left: 5px;
  letter-spacing: 0;
  line-height: 12.2px;
  position: absolute;
  top: 2px;
  white-space: nowrap;
}

.skillbook-case-study .participate-in-the {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
  position: relative;
  width: 246.31px;
}

.skillbook-case-study .pass-an-online {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
  position: relative;
  width: 230.83px;
}

.skillbook-case-study .receive-your {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
  position: relative;
  width: 219.39px;
}

.skillbook-case-study .frame-97 {
  align-items: flex-start;
  background-color: #fff9f1;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.92px 76.05px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-98 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 148.06px;
  margin-right: -9.2px;
  position: relative;
}

.skillbook-case-study .text-wrapper-62 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  height: 38.36px;
  letter-spacing: 0.09px;
  line-height: 17.6px;
  margin-top: -0.67px;
  position: relative;
  width: 284px;
}

.skillbook-case-study .frame-99 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 13.46px;
  position: relative;
}

.skillbook-case-study .check-2 {
  height: 9px;
  left: 399px;
  position: absolute;
  top: 7955px;
  width: 9px;
}

.skillbook-case-study .div-5 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.02px;
  line-height: 12.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-63 {
  font-weight: 500;
}

.skillbook-case-study .check-3 {
  height: 9px;
  left: 399px;
  position: absolute;
  top: 7928px;
  width: 9px;
}

.skillbook-case-study .check-4 {
  height: 9px;
  left: 399px;
  position: absolute;
  top: 7901px;
  width: 9px;
}

.skillbook-case-study .check-5 {
  height: 9px;
  left: 399px;
  position: absolute;
  top: 7868px;
  width: 9px;
}

.skillbook-case-study .scrum-practices {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.02px;
  line-height: 13.7px;
  margin-top: -0.67px;
  position: relative;
  width: 285.35px;
}

.skillbook-case-study .text-wrapper-64 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.02px;
  line-height: 13.7px;
}

.skillbook-case-study .check-6 {
  height: 9px;
  left: 399px;
  position: absolute;
  top: 7835px;
  width: 9px;
}

.skillbook-case-study .text-wrapper-65 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.02px;
  line-height: 12.1px;
}

.skillbook-case-study .frame-100 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 15.48px;
  position: relative;
}

.skillbook-case-study .check-7 {
  height: 9px;
  left: 399px;
  position: absolute;
  top: 7802px;
  width: 9px;
}

.skillbook-case-study .people-management {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.02px;
  line-height: 13.7px;
  margin-top: -0.67px;
  position: relative;
  width: 341.2px;
}

.skillbook-case-study .check-8 {
  height: 9px;
  left: 399px;
  position: absolute;
  top: 7770px;
  width: 9px;
}

.skillbook-case-study .component-13-instance {
  height: 246.99px !important;
  position: relative !important;
  width: 305.31px !important;
}

.skillbook-case-study .component-18 {
  height: 247px !important;
  left: -126px !important;
  top: 8001px !important;
  width: 305px !important;
}

.skillbook-case-study .component-19 {
  height: 199px !important;
  left: 155px !important;
  top: 25px !important;
  width: 151px !important;
}

.skillbook-case-study .component-20 {
  height: 199px !important;
  top: 24px !important;
  width: 151px !important;
}

.skillbook-case-study .component-21 {
  height: 247px !important;
  left: 57px !important;
  width: 187px !important;
}

.skillbook-case-study .frame-101 {
  align-items: flex-start;
  background-color: #f3f3f3;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24.23px;
  padding: 37.69px 53.17px;
  position: relative;
  width: 967.75px;
}

.skillbook-case-study .text-wrapper-66 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: 0.09px;
  line-height: 17.6px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-102 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 22.21px;
  position: relative;
}

.skillbook-case-study .frame-103 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 18.84px;
  position: relative;
}

.skillbook-case-study .frame-104 {
  align-items: flex-start;
  background-color: var(--graywhite);
  border-radius: 5.38px;
  box-shadow: 0px 2.02px 8.08px #4b4b4b14;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  padding: 10.77px 16.15px;
  position: relative;
  width: 274.58px;
}

.skillbook-case-study .frame-105 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.04px;
  position: relative;
}

.skillbook-case-study .group-22 {
  height: 23.55px;
  margin-bottom: -7623.57px;
  margin-right: -213.25px;
  position: relative;
  width: 23.55px;
}

.skillbook-case-study .get-trained-by {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.8px;
  margin-top: -0.67px;
  position: relative;
  width: 176.99px;
}

.skillbook-case-study .text-wrapper-67 {
  color: #fb5741;
  font-weight: 600;
}

.skillbook-case-study .frame-106 {
  align-items: flex-start;
  background-color: var(--graywhite);
  border-radius: 5.38px;
  box-shadow: 0px 2.02px 8.08px #4b4b4b14;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  padding: 10.77px 16.15px;
  position: relative;
  width: 273.23px;
}

.skillbook-case-study .frame-107 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.04px;
  margin-right: -14.13px;
  position: relative;
}

.skillbook-case-study .pen-nib {
  height: 25.57px;
  margin-bottom: -7624.58px;
  position: relative;
  width: 25.57px;
}

.skillbook-case-study .div-6 {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18.8px;
  margin-top: -0.67px;
  position: relative;
  width: 218.72px;
}

.skillbook-case-study .frame-108 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.04px;
  margin-right: -7.54px;
  position: relative;
}

.skillbook-case-study .group-23 {
  height: 25.41px;
  margin-bottom: -7624.49px;
  margin-left: -184.49px;
  position: relative;
  width: 20.32px;
}

.skillbook-case-study .frame-109 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.04px;
  margin-right: -14.94px;
  position: relative;
}

.skillbook-case-study .frame-110 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 18.17px;
  position: relative;
}

.skillbook-case-study .group-24 {
  height: 25.41px;
  margin-bottom: -7542.75px;
  margin-right: -164.12px;
  position: relative;
  width: 20.32px;
}

.skillbook-case-study .group-25 {
  height: 26.92px;
  margin-bottom: -7543.51px;
  position: relative;
  width: 26.92px;
}

.skillbook-case-study .frame-111 {
  align-items: flex-start;
  background-color: var(--graywhite);
  border-radius: 5.38px;
  box-shadow: 0px 2.02px 8.08px #4b4b4b14;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  height: 59.22px;
  padding: 10.77px 16.15px;
  position: relative;
  width: 274.58px;
}

.skillbook-case-study .frame-112 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4.04px;
  justify-content: center;
  margin-bottom: -0.67px;
  position: relative;
}

.skillbook-case-study .pen-nib-2 {
  height: 24.23px;
  margin-bottom: -7541.98px;
  margin-left: -184.49px;
  position: relative;
  width: 23.55px;
}

.skillbook-case-study .element-pdus-and-seus {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  height: 38.36px;
  letter-spacing: -0.02px;
  line-height: 12.9px;
  margin-top: -0.67px;
  position: relative;
  width: 113.06px;
}

.skillbook-case-study .text-wrapper-68 {
  color: #383838;
}

.skillbook-case-study .text-wrapper-69 {
  color: #fb614c;
  font-weight: 600;
}

.skillbook-case-study .frame-113 {
  align-items: flex-start;
  background-color: #27282c;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.92px 54.51px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-114 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 22.21px;
  position: relative;
}

.skillbook-case-study .frame-115 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14.13px;
  padding: 15.48px 0px;
  position: relative;
}

.skillbook-case-study .text-wrapper-70 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 355.34px;
}

.skillbook-case-study .this-course-is-ideal {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 15.1px;
  position: relative;
  width: 294.09px;
}

.skillbook-case-study .buttons {
  align-items: flex-start;
  background-color: #fb5741;
  border-radius: 5.38px;
  display: flex;
  gap: 6.73px;
  height: 30.96px;
  overflow: hidden;
  padding: 9.42px 24.23px 10.09px 15.48px;
  position: relative;
  width: 101.62px;
}

.skillbook-case-study .text-wrapper-71 {
  color: var(--white);
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -0.89px;
  margin-right: -8.09px;
  margin-top: -0.67px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-116 {
  align-items: flex-start;
  border-radius: 11.44px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.77px;
  position: relative;
}

.skillbook-case-study .frame-117 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.77px;
  position: relative;
}

.skillbook-case-study .component-2-instance {
  border-radius: 5.38px !important;
  flex: 0 0 auto !important;
  gap: 6.73px !important;
  padding: 6.73px 8.08px !important;
}

.skillbook-case-study .component-22 {
  color: #383838 !important;
  font-size: 10.1px !important;
  font-weight: 700 !important;
  letter-spacing: -0.3px !important;
  line-height: 14px !important;
  margin-top: -0.67px !important;
}

.skillbook-case-study .component-23 {
  color: #383838 !important;
  font-size: 10.8px !important;
  letter-spacing: -0.32px !important;
  line-height: 15px !important;
  margin-top: -0.67px !important;
}

.skillbook-case-study .frame-118 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10.77px;
  position: relative;
}

.skillbook-case-study .frame-119 {
  align-items: flex-start;
  background-color: #fff9f1;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  height: 218.05px;
  padding: 26.92px 93.54px 26.92px 53.84px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-120 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 168.92px;
  position: relative;
  width: 753.74px;
}

.skillbook-case-study .frame-121 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  position: relative;
}

.skillbook-case-study .text-wrapper-72 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  height: 38.36px;
  letter-spacing: 0.09px;
  line-height: 17.6px;
  margin-top: -0.67px;
  position: relative;
  width: 286.02px;
}

.skillbook-case-study .text-wrapper-73 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
  width: 298.13px;
}

.skillbook-case-study .frame-122 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.77px;
  margin-right: -36.34px;
  position: relative;
}

.skillbook-case-study .text-wrapper-74 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  height: 38.36px;
  letter-spacing: 0.09px;
  line-height: 17.6px;
  margin-top: -0.67px;
  position: relative;
  width: 253.72px;
}

.skillbook-case-study .check-9 {
  height: 9px;
  left: -46px;
  position: absolute;
  top: 7190px;
  width: 9px;
}

.skillbook-case-study .div-7 {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
  position: relative;
  width: 298.13px;
}

.skillbook-case-study .text-wrapper-75 {
  color: #fb5741;
  font-weight: 700;
}

.skillbook-case-study .check-10 {
  height: 9px;
  left: -46px;
  position: absolute;
  top: 7163px;
  width: 9px;
}

.skillbook-case-study .check-11 {
  height: 9px;
  left: -46px;
  position: absolute;
  top: 7130px;
  width: 9px;
}

.skillbook-case-study .you-must-score-at {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14.1px;
  margin-top: -0.67px;
  position: relative;
  width: 233.53px;
}

.skillbook-case-study .frame-123 {
  align-items: flex-start;
  background-color: #0e3b65;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.25px 124.5px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-124 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21.54px;
  position: relative;
}

.skillbook-case-study .text-wrapper-76 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-125 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 31.63px;
  position: relative;
}

.skillbook-case-study .frame-126 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 43.07px;
  position: relative;
}

.skillbook-case-study .frame-127 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24.9px;
  position: relative;
}

.skillbook-case-study .clock {
  height: 26.25px;
  margin-bottom: -6927.64px;
  margin-right: -87.4px;
  position: relative;
  width: 26.25px;
}

.skillbook-case-study .text-wrapper-77 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.5px;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 217.37px;
}

.skillbook-case-study .text-wrapper-78 {
  color: #e7e7e7;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
  width: 234.2px;
}

.skillbook-case-study .ok {
  height: 24.9px;
  margin-bottom: -6831.84px;
  margin-right: -51.06px;
  position: relative;
  width: 24.9px;
}

.skillbook-case-study .text-wrapper-79 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.5px;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 270.54px;
}

.skillbook-case-study .diploma {
  height: 23.55px;
  margin-bottom: -6736.04px;
  margin-right: -34.23px;
  position: relative;
  width: 23.55px;
}

.skillbook-case-study .text-wrapper-80 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.5px;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 287.36px;
}

.skillbook-case-study .text-wrapper-81 {
  color: #e7e7e7;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
  width: 287.36px;
}

.skillbook-case-study .frame-128 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 35px;
  position: relative;
}

.skillbook-case-study .frame-129 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20.19px;
  position: relative;
}

.skillbook-case-study .online {
  height: 32.3px;
  margin-bottom: -6919.7px;
  margin-left: -20.95px;
  position: relative;
  width: 32.3px;
}

.skillbook-case-study .text-wrapper-82 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.5px;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 283.33px;
}

.skillbook-case-study .frame-130 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 20.86px;
  position: relative;
}

.skillbook-case-study .membership-card {
  height: 24.9px;
  margin-bottom: -6811.92px;
  margin-left: -20.95px;
  position: relative;
  width: 30.96px;
}

.skillbook-case-study .text-wrapper-83 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 13.5px;
  font-weight: 700;
  letter-spacing: 0.07px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 281.31px;
}

.skillbook-case-study .skillbook-is-a-scrum {
  color: #e7e7e7;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
  width: 281.31px;
}

.skillbook-case-study .membership-card-2 {
  height: 24.9px;
  margin-bottom: -6711.54px;
  margin-left: -20.95px;
  position: relative;
  width: 30.96px;
}

.skillbook-case-study .frame-131 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: 2466px;
}

.skillbook-case-study .group-26 {
  height: 618.37px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-132 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 26.92px;
  position: relative;
}

.skillbook-case-study .frame-133 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.skillbook-case-study .text-wrapper-84 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  height: 30.28px;
  letter-spacing: 0.09px;
  line-height: 17.6px;
  margin-top: -0.67px;
  position: relative;
  width: 268.52px;
}

.skillbook-case-study .text-wrapper-85 {
  color: #fb5741;
  font-family: "Manrope", Helvetica;
  font-size: 13.5px;
  font-weight: 700;
  height: 38.36px;
  letter-spacing: 0.07px;
  line-height: 13.6px;
  position: relative;
  text-align: center;
  width: 286.02px;
}

.skillbook-case-study .text-wrapper-86 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
  text-align: center;
  width: 604.34px;
}

.skillbook-case-study .frame-134 {
  align-items: flex-start;
  background-color: #f4fdf8;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.92px 63.26px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-135 {
  height: 440.97px;
  position: relative;
  width: 827.1px;
}

.skillbook-case-study .frame-136 {
  align-items: center;
  display: inline-flex;
  gap: 154.11px;
  left: 0;
  position: absolute;
  top: 44px;
}

.skillbook-case-study .group-27 {
  height: 204.59px;
  position: relative;
  width: 331.78px;
}

.skillbook-case-study .overlap-9 {
  background-image: url(../../../static/img/vector-6.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 205px;
  left: 408px;
  position: absolute;
  top: 6423px;
  width: 318px;
}

.skillbook-case-study .group-28 {
  height: 48px;
  left: 13px;
  position: absolute;
  top: 151px;
  width: 178px;
}

.skillbook-case-study .frame-137 {
  align-items: flex-start;
  display: flex;
  height: 20.19px;
  justify-content: space-between;
  left: 214px;
  position: absolute;
  top: 14px;
  width: 118px;
}

.skillbook-case-study .avatar-w-photo {
  background-image: url(../../../static/img/vector-6.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 672.31px;
  height: 24.23px;
  margin-bottom: -4.04px;
  position: relative;
  width: 24.23px;
}

.skillbook-case-study .outside-stroke {
  border: 1.35px solid;
  border-color: var(--black-white-white);
  border-radius: 13.46px;
  height: 27px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 27px;
}

.skillbook-case-study .outside-stroke-wrapper {
  background-image: url(../../../static/img/vector-6.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 672.31px;
  height: 24.23px;
  margin-bottom: -4.04px;
  margin-left: -0.84px;
  position: relative;
  width: 24.23px;
}

.skillbook-case-study .avatar-w-photo-2 {
  background-color: #fb5741;
  background-image: url(../../../static/img/vector-6.png);
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 672.31px;
  height: 24.23px;
  margin-bottom: -4.04px;
  margin-left: -0.84px;
  position: relative;
  width: 24.23px;
}

.skillbook-case-study .overlap-group-10 {
  border: 1.35px solid;
  border-color: #ffffff;
  border-radius: 13.46px;
  height: 27px;
  left: -1px;
  position: relative;
  top: -1px;
  width: 27px;
}

.skillbook-case-study .text-wrapper-87 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 8.1px;
  font-weight: 700;
  height: 8px;
  left: 5px;
  letter-spacing: 0.04px;
  line-height: 8.1px;
  position: absolute;
  top: 7px;
  white-space: nowrap;
}

.skillbook-case-study .group-29 {
  height: 27px;
  left: 287px;
  position: absolute;
  top: 136px;
  width: 29px;
}

.skillbook-case-study .overlap-10 {
  height: 27px;
  left: 408px;
  position: relative;
  top: 6423px;
}

.skillbook-case-study .ellipse-4 {
  height: 27px;
  left: 2px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 27px;
}

.skillbook-case-study .emoji-smiling-face {
  height: 12px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 12px;
}

.skillbook-case-study .group-30 {
  height: 145.24px;
  position: relative;
  width: 341.2px;
}

.skillbook-case-study .frame-138 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  position: relative;
  width: 341px;
}

.skillbook-case-study .text-wrapper-88 {
  color: #fb5741;
  font-family: "Inter", Helvetica;
  font-size: 13.5px;
  font-weight: 700;
  height: 20.86px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  width: 321.69px;
}

.skillbook-case-study .text-wrapper-89 {
  align-self: stretch;
  color: #222222;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
}

.skillbook-case-study .frame-139 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 6.73px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-140 {
  background-color: #fb5741;
  border-radius: 1.35px;
  height: 8.08px;
  position: relative;
  width: 8.08px;
}

.skillbook-case-study .check-12 {
  height: 4px;
  left: -76px;
  position: absolute;
  top: 6344px;
  width: 4px;
}

.skillbook-case-study .text-wrapper-90 {
  color: #383838;
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  margin-top: -0.67px;
  position: relative;
}

.skillbook-case-study .check-13 {
  height: 4px;
  left: -76px;
  position: absolute;
  top: 6323px;
  width: 4px;
}

.skillbook-case-study .check-14 {
  height: 4px;
  left: -76px;
  position: absolute;
  top: 6302px;
  width: 4px;
}

.skillbook-case-study .check-15 {
  height: 4px;
  left: -76px;
  position: absolute;
  top: 6282px;
  width: 4px;
}

.skillbook-case-study .check-16 {
  height: 4px;
  left: -76px;
  position: absolute;
  top: 6261px;
  width: 4px;
}

.skillbook-case-study .text-wrapper-91 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  height: 24px;
  left: 279px;
  letter-spacing: -0.52px;
  line-height: 24.3px;
  position: absolute;
  top: -1px;
  width: 322px;
}

.skillbook-case-study .frame-141 {
  align-items: center;
  display: inline-flex;
  gap: 142px;
  left: 26px;
  position: absolute;
  top: 275px;
}

.skillbook-case-study .text-wrapper-92 {
  color: #222222;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
  width: 357.35px;
}

.skillbook-case-study .check-17 {
  height: 4px;
  left: 384px;
  position: absolute;
  top: 6122px;
  width: 4px;
}

.skillbook-case-study .check-18 {
  height: 4px;
  left: 384px;
  position: absolute;
  top: 6101px;
  width: 4px;
}

.skillbook-case-study .check-19 {
  height: 4px;
  left: 384px;
  position: absolute;
  top: 6080px;
  width: 4px;
}

.skillbook-case-study .check-20 {
  height: 4px;
  left: 384px;
  position: absolute;
  top: 6059px;
  width: 4px;
}

.skillbook-case-study .group-31 {
  height: 166.23px;
  margin-bottom: -6191.8px;
  margin-right: -382.17px;
  position: relative;
  width: 267.17px;
}

.skillbook-case-study .frame-142 {
  align-items: flex-end;
  background-color: #e7e7e7;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  justify-content: center;
  padding: 26.92px 59.9px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-143 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  justify-content: center;
  margin-left: -14.81px;
  position: relative;
}

.skillbook-case-study .frame-144 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10.77px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .text-wrapper-93 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: 0.09px;
  line-height: 19px;
  margin-top: -0.67px;
  position: relative;
  width: 192.47px;
}

.skillbook-case-study .text-wrapper-94 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0.05px;
  line-height: 14.1px;
  position: relative;
  text-align: center;
  width: 406.48px;
}

.skillbook-case-study .mentor {
  height: 210.64px;
  position: relative;
  width: 205.93px;
}

.skillbook-case-study .rectangle-9 {
  height: 211px;
  left: 426px;
  object-fit: cover;
  position: absolute;
  top: 5892px;
  width: 206px;
}

.skillbook-case-study .group-32 {
  height: 57px;
  left: 16px;
  position: absolute;
  top: 121px;
  width: 174px;
}

.skillbook-case-study .frame-145 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 5.38px;
  position: relative;
}

.skillbook-case-study .text-wrapper-95 {
  color: #ffffff;
  font-family: var(--h2-subtext-font-family);
  font-size: var(--h2-subtext-font-size);
  font-style: var(--h2-subtext-font-style);
  font-weight: var(--h2-subtext-font-weight);
  letter-spacing: var(--h2-subtext-letter-spacing);
  line-height: var(--h2-subtext-line-height);
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-96 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: relative;
  width: 174.3px;
}

.skillbook-case-study .rectangle-10 {
  height: 211px;
  left: 207px;
  object-fit: cover;
  position: absolute;
  top: 5892px;
  width: 206px;
}

.skillbook-case-study .group-33 {
  height: 57px;
  left: 16px;
  position: absolute;
  top: 122px;
  width: 174px;
}

.skillbook-case-study .text-wrapper-97 {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: -0.52px;
  line-height: 24.3px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .mentor-cards {
  height: 211.32px;
  position: relative;
  width: 205.93px;
}

.skillbook-case-study .rectangle-11 {
  height: 211px;
  left: -13px;
  object-fit: cover;
  position: absolute;
  top: 5892px;
  width: 206px;
}

.skillbook-case-study .group-34 {
  height: 57px;
  left: 16px;
  position: absolute;
  top: 120px;
  width: 137px;
}

.skillbook-case-study .text-wrapper-98 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: relative;
  width: 137.29px;
}

.skillbook-case-study .rectangle-12 {
  height: 209.3px;
  margin-bottom: -5891.42px;
  margin-right: -425.91px;
  object-fit: cover;
  position: relative;
  width: 205.93px;
}

.skillbook-case-study .frame-146 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 5.38px;
  left: 674px;
  position: absolute;
  top: 123px;
}

.skillbook-case-study .frame-147 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12.79px;
  justify-content: flex-end;
  position: relative;
}

.skillbook-case-study .frame-148 {
  align-items: flex-end;
  display: inline-flex;
  gap: 12.79px;
  justify-content: flex-end;
  left: 217px;
  position: absolute;
  top: 3473px;
}

.skillbook-case-study .frame-149 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 15.48px;
  position: relative;
}

.skillbook-case-study .frame-150 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  position: relative;
}

.skillbook-case-study .frame-151 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.38px;
  position: relative;
}

.skillbook-case-study .text-wrapper-99 {
  color: #fb5741;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: 0.05px;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-100 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-152 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16.15px;
  position: relative;
}

.skillbook-case-study .col-md {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 152.09px;
}

.skillbook-case-study .card {
  align-items: flex-start;
  background-color: var(--light-background-color);
  border: 0.67px solid;
  border-color: #d2d2d2;
  border-radius: 5.38px;
  box-shadow: 0px 8.75px 12.79px #00000012;
  display: flex;
  flex-direction: column;
  gap: 13.46px;
  height: 99.6px;
  overflow: hidden;
  padding: 10.09px 26.92px 23.55px 10.09px;
  position: relative;
  width: 152.09px;
}

.skillbook-case-study .fixed-width {
  height: 37.69px;
  margin-bottom: -23.55px;
  margin-right: -172.87px;
  position: relative;
  width: 34.32px;
}

.skillbook-case-study .feature-title {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15.1px;
  margin-bottom: -14.52px;
  margin-right: -20.19px;
  position: relative;
  width: 135.27px;
}

.skillbook-case-study .fixed-width-2 {
  height: 37.69px;
  margin-bottom: -23.55px;
  margin-right: -4.62px;
  position: relative;
  width: 34.32px;
}

.skillbook-case-study .h-feature-title {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15.1px;
  margin-bottom: -14.52px;
  margin-right: -12.11px;
  position: relative;
  width: 127.19px;
}

.skillbook-case-study .fixed-width-3 {
  height: 37.69px;
  margin-bottom: -23.55px;
  margin-left: -82.87px;
  position: relative;
  width: 34.32px;
}

.skillbook-case-study .feature-title-2 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 15.1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-153 {
  align-items: center;
  border-radius: 5.38px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6.73px;
  justify-content: center;
  padding: 11.44px 0px;
  position: relative;
}

.skillbook-case-study .text-wrapper-101 {
  color: #fb5741;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.skillbook-case-study .download-from-the {
  height: 18.51px;
  margin-bottom: -5380.75px;
  margin-left: -128.02px;
  position: relative;
  width: 18.51px;
}

.skillbook-case-study .overlap-11 {
  height: 1878px;
  left: 0;
  position: absolute;
  top: 3786px;
  width: 969px;
}

.skillbook-case-study .frame-154 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40.38px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .component-24-instance {
  flex: 0 0 auto !important;
  gap: 40.38px !important;
}

.skillbook-case-study .component-25 {
  gap: 6.73px !important;
  padding: 26.92px 53.84px !important;
}

.skillbook-case-study .component-26 {
  gap: 100.95px !important;
  width: 861.42px !important;
}

.skillbook-case-study .component-27 {
  gap: 16.15px !important;
}

.skillbook-case-study .component-28 {
  color: #222222 !important;
  margin-top: -0.67px !important;
}

.skillbook-case-study .component-29 {
  height: 176.32px !important;
  width: 563.29px !important;
}

.skillbook-case-study .component-30 {
  border-radius: 2.69px !important;
  gap: 6.73px !important;
  padding: 5.38px !important;
}

.skillbook-case-study .component-31 {
  border-radius: 1.35px !important;
  gap: 6.73px !important;
  padding: 2.02px !important;
}

.skillbook-case-study .component-32 {
  border-radius: 4.4px !important;
  height: 16.15px !important;
  width: 16.15px !important;
}

.skillbook-case-study .component-33 {
  height: 5px !important;
  left: 417px !important;
  top: 5220px !important;
  width: 6px !important;
}

.skillbook-case-study .component-34 {
  color: #383838 !important;
  font-size: 10.1px !important;
  letter-spacing: 0.05px !important;
  line-height: 14.1px !important;
  margin-top: -0.67px !important;
  width: 219.39px !important;
}

.skillbook-case-study .component-35 {
  border-radius: 2.69px !important;
  gap: 6.73px !important;
  height: 53px !important;
  padding: 5.38px !important;
  top: 64px !important;
}

.skillbook-case-study .component-36 {
  height: 5px !important;
  left: 417px !important;
  top: 5156px !important;
  width: 6px !important;
}

.skillbook-case-study .component-37 {
  color: #383838 !important;
  font-size: 10.1px !important;
  letter-spacing: 0.05px !important;
  line-height: 14.1px !important;
  width: 219.39px !important;
}

.skillbook-case-study .component-38 {
  border-radius: 2.69px !important;
  gap: 6.73px !important;
  padding: 5.38px !important;
  top: 128px !important;
}

.skillbook-case-study .component-39 {
  height: 5px !important;
  left: 417px !important;
  top: 5092px !important;
  width: 6px !important;
}

.skillbook-case-study .component-40 {
  border-radius: 2.69px !important;
  gap: 6.73px !important;
  height: 53px !important;
  left: 274px !important;
  padding: 5.38px !important;
}

.skillbook-case-study .component-41 {
  height: 5px !important;
  left: 143px !important;
  top: 5220px !important;
  width: 6px !important;
}

.skillbook-case-study .component-42 {
  border-radius: 2.69px !important;
  gap: 6.73px !important;
  left: 274px !important;
  padding: 5.38px !important;
  top: 64px !important;
}

.skillbook-case-study .component-43 {
  height: 5px !important;
  left: 143px !important;
  top: 5156px !important;
  width: 6px !important;
}

.skillbook-case-study .component-44 {
  border-radius: 2.69px !important;
  gap: 6.73px !important;
  left: 274px !important;
  padding: 5.38px !important;
  top: 128px !important;
}

.skillbook-case-study .component-45 {
  height: 5px !important;
  left: 143px !important;
  top: 5092px !important;
  width: 6px !important;
}

.skillbook-case-study .component-46 {
  height: 121px !important;
  left: 1081px !important;
  top: 5489px !important;
  width: 121px !important;
}

.skillbook-case-study .frame-155 {
  align-items: flex-start;
  background-color: #f4fdf8;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  padding: 26.92px 53.84px;
  position: relative;
}

.skillbook-case-study .frame-156 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 32.98px;
  position: relative;
  width: 861.42px;
}

.skillbook-case-study .frame-157 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 2.69px;
  position: relative;
}

.skillbook-case-study .text-wrapper-102 {
  color: #222222;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: -0.44px;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-103 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: 382.26px;
}

.skillbook-case-study .frame-158 {
  height: 63.26px;
  overflow: hidden;
  position: relative;
  width: 129.21px;
}

.skillbook-case-study .group-35 {
  height: 63px;
  position: relative;
  width: 424px;
}

.skillbook-case-study .text-wrapper-104 {
  color: #383838;
  font-family: "Manrope", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  left: 28px;
  letter-spacing: 0;
  line-height: 16.2px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.skillbook-case-study .rectangle-13 {
  background-color: #383838;
  border-radius: 4.4px;
  height: 16px;
  left: 0;
  position: absolute;
  top: 1px;
  width: 16px;
}

.skillbook-case-study .group-36 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 94px;
}

.skillbook-case-study .vector-10 {
  height: 5px;
  left: 422px;
  position: absolute;
  top: 4855px;
  width: 6px;
}

.skillbook-case-study .group-37 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 46px;
  width: 94px;
}

.skillbook-case-study .vector-11 {
  height: 5px;
  left: 833px;
  position: absolute;
  top: 4871px;
  width: 6px;
}

.skillbook-case-study .JOURNEY-OF-SCRUM {
  height: 236.22px;
  margin-bottom: -5000.07px;
  margin-right: -429.95px;
  object-fit: cover;
  position: relative;
  width: 458.98px;
}

.skillbook-case-study .group-38 {
  height: 249.04px;
  position: relative;
  width: 969.1px;
}

.skillbook-case-study .frame-159 {
  align-items: flex-start;
  background-color: #0e3b65;
  display: flex;
  flex-direction: column;
  gap: 6.73px;
  padding: 30.28px 59.22px;
  position: relative;
  width: 969px;
}

.skillbook-case-study .frame-160 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 72.68px;
  position: relative;
}

.skillbook-case-study .frame-161 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.15px;
  position: relative;
  width: 405.81px;
}

.skillbook-case-study .upskilling {
  color: #ffffff;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: -0.44px;
  line-height: normal;
  margin-right: -75.37px;
  margin-top: -0.67px;
  position: relative;
  width: 481.18px;
}

.skillbook-case-study .text-wrapper-105 {
  color: #e0e0e0;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: relative;
  width: 405.81px;
}

.skillbook-case-study .CTA {
  align-items: flex-end;
  background-color: #fb5741;
  border-radius: 3.36px;
  display: inline-flex;
  gap: 6.73px;
  height: 30.96px;
  justify-content: flex-end;
  padding: 10.77px 16.15px 8.75px;
  position: relative;
}

.skillbook-case-study .request-more {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -2.23px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .group-39 {
  height: 188.47px;
  position: relative;
  width: 327.8px;
}

.skillbook-case-study .overlap-group-11 {
  height: 188px;
  position: relative;
  width: 328px;
}

.skillbook-case-study .group-40 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 143px;
  width: 328px;
}

.skillbook-case-study .group-41 {
  height: 45px;
  position: relative;
}

.skillbook-case-study .paypal-logo {
  height: 38px;
  left: 412px;
  position: absolute;
  top: 4710px;
  width: 68px;
}

.skillbook-case-study .cognizant-logo {
  height: 45px;
  left: 488px;
  object-fit: cover;
  position: absolute;
  top: 4707px;
  width: 76px;
}

.skillbook-case-study .ericsson-logo {
  height: 34px;
  left: 675px;
  object-fit: cover;
  position: absolute;
  top: 4712px;
  width: 64px;
}

.skillbook-case-study .acc-logo-black {
  height: 18px;
  left: 578px;
  object-fit: cover;
  position: absolute;
  top: 4716px;
  width: 73px;
}

.skillbook-case-study .group-42 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 108px;
  width: 328px;
}

.skillbook-case-study .group-43 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 71px;
  width: 328px;
}

.skillbook-case-study .group-44 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 36px;
  width: 328px;
}

.skillbook-case-study .group-45 {
  height: 45px;
  left: 0;
  position: absolute;
  top: 0;
  width: 328px;
}

.skillbook-case-study .frame-162 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21.54px;
  justify-content: center;
  padding: 0px 61.91px;
  position: relative;
}

.skillbook-case-study .frame-163 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 5.38px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .have-questions-we-ve {
  color: transparent;
  font-family: "Manrope", Helvetica;
  font-size: 17.5px;
  font-weight: 700;
  letter-spacing: -0.52px;
  line-height: 24.5px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-106 {
  color: #222222;
}

.skillbook-case-study .frame-164 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 21.54px;
  position: relative;
}

.skillbook-case-study .frame-165 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 6.73px;
  position: relative;
  width: 506.08px;
}

.skillbook-case-study .line-3 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -3933px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-166 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  margin-right: -62.59px;
  position: relative;
  width: 568.67px;
}

.skillbook-case-study .frame-167 {
  height: 37.69px;
  overflow: hidden;
  position: relative;
  width: 37.69px;
}

.skillbook-case-study .ellipse-5 {
  background-color: var(--neutrals-2);
  border-radius: 16.82px;
  height: 34px;
  left: 2px;
  position: absolute;
  top: 2px;
  width: 34px;
}

.skillbook-case-study .text-wrapper-107 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: -0.11px;
  line-height: 12.9px;
  position: relative;
  width: 495.99px;
}

.skillbook-case-study .line-4 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -3984.15px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .line-5 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -4035.3px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .line-6 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -4086.44px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .line-7 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -4137.59px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .line-8 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -4188.74px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .text-wrapper-108 {
  color: #383838;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16.2px;
  position: relative;
  width: 495.99px;
}

.skillbook-case-study .line-9 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -4239.88px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .line-10 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -4291.03px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .line-11 {
  align-self: stretch;
  height: 1px;
  margin-bottom: -4342.18px;
  margin-right: -409.09px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .group-46 {
  height: 746px;
  left: 0;
  position: absolute;
  top: 1132px;
  width: 969px;
}

.skillbook-case-study .footers-wrapper {
  height: 473px;
  left: 0;
  position: absolute;
  top: 273px;
  width: 969px;
}

.skillbook-case-study .footers {
  background-color: #000000;
  height: 473px;
  overflow: hidden;
  position: relative;
}

.skillbook-case-study .frame-168 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 8.75px;
  left: 55px;
  position: absolute;
  top: 15px;
}

.skillbook-case-study .group-47 {
  height: 24.49px;
  margin-bottom: -3849.75px;
  margin-right: -381.13px;
  position: relative;
  width: 142px;
}

.skillbook-case-study .property-default-instance {
  height: 11.44px !important;
  left: unset !important;
  margin-bottom: -3840.07px !important;
  margin-right: -284.84px !important;
  position: relative !important;
  top: unset !important;
  width: 14.08px !important;
}

.skillbook-case-study .element-wrapper {
  height: 18.17px;
  position: relative;
  width: 18.17px;
}

.skillbook-case-study .element-3 {
  height: 18px;
  left: 322px;
  object-fit: cover;
  position: absolute;
  top: 3843px;
  width: 18px;
}

.skillbook-case-study .property-1-default-instance {
  height: 9.38px !important;
  left: unset !important;
  margin-bottom: -3839.04px !important;
  margin-right: -382.17px !important;
  position: relative !important;
  top: unset !important;
  width: 12.53px !important;
}

.skillbook-case-study .tiktok-logo-on-black-wrapper {
  height: 16.15px;
  position: relative;
  width: 15.48px;
}

.skillbook-case-study .tiktok-logo-on-black {
  height: 12px;
  left: 256px;
  position: absolute;
  top: 3845px;
  width: 13px;
}

.skillbook-case-study .divider {
  height: 1px;
  left: 471px;
  position: absolute;
  top: 4312px;
  width: 969px;
}

.skillbook-case-study .text-wrapper-109 {
  color: #ffffffd9;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  left: 59px;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: absolute;
  top: 433px;
  white-space: nowrap;
}

.skillbook-case-study .frame-169 {
  align-items: flex-start;
  display: inline-flex;
  gap: 53.17px;
  left: 586px;
  position: absolute;
  top: 433px;
}

.skillbook-case-study .text-wrapper-110 {
  color: #ffffffd9;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  margin-top: -0.67px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-111 {
  color: #ffffffd9;
  font-family: "Manrope", Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  height: 14px;
  left: 55px;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: absolute;
  text-align: center;
  top: 91px;
  white-space: nowrap;
}

.skillbook-case-study .frame-170 {
  align-items: center;
  display: inline-flex;
  gap: 5.38px;
  left: 55px;
  position: absolute;
  top: 141px;
}

.skillbook-case-study .g-4 {
  height: 18.51px;
  margin-bottom: -3766.81px;
  margin-right: -289.29px;
  position: relative;
  width: 15.46px;
}

.skillbook-case-study .this-site-is-SSL {
  color: transparent;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 600;
  height: 23.55px;
  letter-spacing: 0.19px;
  line-height: 8.7px;
  margin-top: -0.67px;
  position: relative;
  text-align: center;
  width: 121.14px;
}

.skillbook-case-study .text-wrapper-112 {
  color: #ffffffd9;
}

.skillbook-case-study .text-wrapper-113 {
  color: #00b67a;
}

.skillbook-case-study .vector-12 {
  height: 1px;
  left: 526px;
  position: absolute;
  top: 4075px;
  width: 860px;
}

.skillbook-case-study .vector-13 {
  height: 1px;
  left: 526px;
  position: absolute;
  top: 4240px;
  width: 860px;
}

.skillbook-case-study .frame-171 {
  align-items: center;
  background-color: #ffedd9;
  border-radius: 4.04px;
  display: inline-flex;
  gap: 6.73px;
  height: 19px;
  justify-content: center;
  left: 326px;
  padding: 6.73px;
  position: absolute;
  top: 27px;
}

.skillbook-case-study .text-wrapper-114 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 10.8px;
  font-weight: 500;
  letter-spacing: -0.32px;
  line-height: 12.1px;
  margin-bottom: -2.64px;
  margin-top: -3.98px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-172 {
  align-items: flex-start;
  display: inline-flex;
  gap: 45.09px;
  left: 55px;
  position: absolute;
  top: 200px;
}

.skillbook-case-study .frame-173 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 26.25px;
  position: relative;
}

.skillbook-case-study .text-wrapper-115 {
  color: #ffffffd9;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-116 {
  color: #ffffffd9;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-117 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-174 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 22.88px;
  position: relative;
}

.skillbook-case-study .group-48 {
  height: 135px;
  left: 627px;
  position: absolute;
  top: 27px;
  width: 155px;
}

.skillbook-case-study .frame-175 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13.46px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
}

.skillbook-case-study .frame-176 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 22.21px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .text-wrapper-118 {
  color: #fb5741;
  font-family: "Inter", Helvetica;
  font-size: 12.1px;
  font-weight: 600;
  letter-spacing: -0.36px;
  line-height: 16.8px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-177 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12.11px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .frame-178 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.42px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .phone {
  height: 13.46px;
  margin-bottom: -3834.37px;
  margin-left: -156.22px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .text-wrapper-119 {
  color: #ffffffd9;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .phone-2 {
  height: 13.46px;
  margin-bottom: -3811.49px;
  margin-left: -156.22px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .phone-3 {
  height: 13.46px;
  margin-bottom: -3788.61px;
  margin-left: -156.22px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .phone-4 {
  height: 13.46px;
  margin-bottom: -3765.73px;
  margin-left: -156.22px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .frame-179 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8.75px;
  position: relative;
}

.skillbook-case-study .envelope {
  height: 12.79px;
  margin-bottom: -3742.85px;
  margin-left: -156.22px;
  position: relative;
  width: 12.79px;
}

.skillbook-case-study .text-wrapper-120 {
  color: #ffffffd9;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -0.28px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .USA {
  height: 22px;
  left: 605px;
  position: absolute;
  top: 3919px;
  width: 22px;
}

.skillbook-case-study .flag-of-canada {
  height: 11px;
  left: 605px;
  position: absolute;
  top: 3946px;
  width: 21px;
}

.skillbook-case-study .flag-united-kingdom {
  height: 11px;
  left: 605px;
  object-fit: cover;
  position: absolute;
  top: 3969px;
  width: 21px;
}

.skillbook-case-study .australia-flag {
  height: 11px;
  left: 605px;
  object-fit: cover;
  position: absolute;
  top: 3992px;
  width: 22px;
}

.skillbook-case-study .group-49 {
  height: 135px;
  left: 413px;
  position: absolute;
  top: 27px;
  width: 153px;
}

.skillbook-case-study .phone-5 {
  height: 13.46px;
  margin-bottom: -3834.37px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .phone-6 {
  height: 13.46px;
  margin-bottom: -3811.49px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .phone-7 {
  height: 13.46px;
  margin-bottom: -3788.61px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .phone-8 {
  height: 13.46px;
  margin-bottom: -3765.73px;
  position: relative;
  width: 13.46px;
}

.skillbook-case-study .envelope-2 {
  height: 12.79px;
  margin-bottom: -3742.85px;
  position: relative;
  width: 12.79px;
}

.skillbook-case-study .USA-2 {
  height: 22px;
  left: 603px;
  position: absolute;
  top: 3919px;
  width: 22px;
}

.skillbook-case-study .flag-of-canada-2 {
  height: 11px;
  left: 604px;
  position: absolute;
  top: 3947px;
  width: 21px;
}

.skillbook-case-study .flag-united-kingdom-2 {
  height: 11px;
  left: 604px;
  object-fit: cover;
  position: absolute;
  top: 3970px;
  width: 21px;
}

.skillbook-case-study .australia-flag-2 {
  height: 11px;
  left: 603px;
  object-fit: cover;
  position: absolute;
  top: 3992px;
  width: 22px;
}

.skillbook-case-study .frame-180 {
  align-items: flex-start;
  display: inline-flex;
  gap: 362.07px;
  left: 59px;
  position: absolute;
  top: 367px;
}

.skillbook-case-study .frame-181 {
  align-items: center;
  border: 0.67px solid;
  border-color: #cccccc;
  border-radius: 4.04px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 88.83px;
  height: 25.57px;
  padding: 6.73px 25.57px;
  position: relative;
}

.skillbook-case-study .frame-182 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 11.44px;
  margin-bottom: -0.94px;
  margin-top: -0.94px;
  position: relative;
}

.skillbook-case-study .frame-183 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 14.13px;
  position: relative;
}

.skillbook-case-study .text-wrapper-121 {
  color: #ffffffb2;
  font-family: "Manrope", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  margin-top: -0.67px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-184 {
  align-items: center;
  display: flex;
  gap: 22.21px;
  position: relative;
  width: 375.53px;
}

.skillbook-case-study .frame-185 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 88.83px;
  position: relative;
}

.skillbook-case-study .frame-186 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 11.44px;
  position: relative;
}

.skillbook-case-study .text-wrapper-122 {
  color: #dadada;
  font-family: "Manrope", Helvetica;
  font-size: 10.8px;
  font-weight: 700;
  letter-spacing: -0.32px;
  line-height: 15px;
  margin-top: -0.67px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-187 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 38.36px;
  margin-right: -0.71px;
  position: relative;
  width: 216.03px;
}

.skillbook-case-study .email-text-input-wrapper {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16.82px;
  position: relative;
  width: 184.4px;
}

.skillbook-case-study .email-text-input {
  align-items: center;
  border: 0.67px solid;
  border-color: #7f7f7f;
  border-radius: 4.04px;
  display: flex;
  gap: 349.95px;
  height: 25.57px;
  margin-right: -32.98px;
  padding: 1.35px 0px 1.35px 16.15px;
  position: relative;
  width: 217.37px;
}

.skillbook-case-study .placeholder {
  color: #ffffff73;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 14px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .button {
  all: unset;
  align-items: center;
  background-color: #51b37f;
  border-radius: 0px 5.38px 5.38px 0px;
  box-sizing: border-box;
  display: flex;
  gap: 6.73px;
  height: 26px;
  justify-content: center;
  left: 162px;
  overflow: hidden;
  padding: 10.77px 24.23px;
  position: absolute;
  top: 0;
  width: 59px;
}

.skillbook-case-study .text-wrapper-123 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -2.81px;
  margin-left: -12.45px;
  margin-right: -12.45px;
  margin-top: -4.15px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-188 {
  align-items: flex-start;
  display: inline-flex;
  gap: 8.08px;
  left: 55px;
  position: absolute;
  top: 115px;
}

.skillbook-case-study .paypal-instance {
  height: 17.61px !important;
  left: unset !important;
  margin-bottom: -3777.34px !important;
  margin-right: -224.99px !important;
  position: relative !important;
  top: unset !important;
  width: 39.63px !important;
}

.skillbook-case-study .paypal-1 {
  height: 17.61px !important;
  left: unset !important;
  margin-bottom: -3777.34px !important;
  margin-right: -272.7px !important;
  position: relative !important;
  top: unset !important;
  width: 39.63px !important;
}

.skillbook-case-study .paypal-2 {
  height: 17.61px !important;
  left: unset !important;
  margin-bottom: -3777.34px !important;
  margin-right: -320.4px !important;
  position: relative !important;
  top: unset !important;
  width: 39.63px !important;
}

.skillbook-case-study .discover-card-logo-wrapper {
  align-items: center;
  border: 0.67px solid;
  border-color: #ffffff;
  border-radius: 1.47px;
  display: flex;
  flex-direction: column;
  gap: 7.34px;
  height: 17.61px;
  justify-content: center;
  padding: 4.4px 2.94px;
  position: relative;
  width: 39.63px;
}

.skillbook-case-study .discover-card-logo-2 {
  height: 5.38px;
  overflow: hidden;
  position: relative;
  width: 28.94px;
}

.skillbook-case-study .g-5 {
  height: 5px;
  left: 267px;
  position: absolute;
  top: 3777px;
  width: 29px;
}

.skillbook-case-study .ellipse-6 {
  background-color: #d9d9d9;
  border-radius: 2.36px;
  height: 5px;
  left: 13px;
  position: absolute;
  top: 1px;
  width: 5px;
}

.skillbook-case-study .paypal-3 {
  height: 17.61px !important;
  left: unset !important;
  margin-bottom: -3777.34px !important;
  margin-right: -415.82px !important;
  position: relative !important;
  top: unset !important;
  width: 39.63px !important;
}

.skillbook-case-study .talk-to-our-learning {
  background-color: #ffffff;
  border-radius: 4.04px;
  box-shadow: 0px -2.02px 14.81px #0000002e;
  height: 273px;
  left: 677px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 238px;
}

.skillbook-case-study .overlap-group-12 {
  background-color: #ffffff;
  height: 299px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.skillbook-case-study .overlap-12 {
  background-color: #000000;
  height: 23px;
  left: 0;
  position: absolute;
  top: 0;
  width: 238px;
}

.skillbook-case-study .text-wrapper-124 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 500;
  left: 59px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 5px;
  width: 122px;
}

.skillbook-case-study .frame-189 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 13.46px;
  left: 22px;
  position: absolute;
  top: 73px;
}

.skillbook-case-study .frame-190 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 9.42px;
  position: relative;
}

.skillbook-case-study .container-email {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.38px;
  height: 23.55px;
  position: relative;
  width: 193.15px;
}

.skillbook-case-study .input-field {
  align-items: center;
  align-self: stretch;
  border: 0.67px solid;
  border-color: var(--neutral-80);
  border-radius: 2.69px;
  display: flex;
  gap: 6.73px;
  height: 23.55px;
  padding: 9.42px 10.77px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .text-wrapper-125 {
  color: var(--neutral-60);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 11.2px;
  margin-bottom: -2.47px;
  margin-top: -3.82px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .input-field-wrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 5.38px;
  height: 47.11px;
  position: relative;
  width: 193.15px;
}

.skillbook-case-study .input-field-2 {
  align-items: flex-start;
  align-self: stretch;
  border: 0.67px solid;
  border-color: var(--neutral-80);
  border-radius: 2.69px;
  display: flex;
  gap: 6.73px;
  height: 47.11px;
  padding: 5.38px 10.77px 9.42px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .text-wrapper-126 {
  color: var(--neutral-60);
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 11.2px;
  margin-top: -0.67px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .CTA-2 {
  align-items: center;
  background-color: #fb614c;
  border-radius: 5.38px;
  display: flex;
  gap: 6.73px;
  height: 22.88px;
  justify-content: center;
  padding: 9.42px 10.77px;
  position: relative;
  width: 193.15px;
}

.skillbook-case-study .text-wrapper-127 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 10.1px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-bottom: -3.31px;
  margin-top: -4.65px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-128 {
  color: #222222;
  font-family: "Inter", Helvetica;
  font-size: 9.4px;
  font-weight: 500;
  height: 13px;
  left: 30px;
  letter-spacing: -0.28px;
  line-height: 13.1px;
  position: absolute;
  top: 32px;
  white-space: nowrap;
}

.skillbook-case-study .text-wrapper-129 {
  color: #fb614c;
  font-family: "Inter", Helvetica;
  font-size: 8.1px;
  font-weight: 500;
  height: 11px;
  left: 75px;
  letter-spacing: -0.24px;
  line-height: 11.2px;
  position: absolute;
  top: 49px;
  white-space: nowrap;
}

.skillbook-case-study .vector-14 {
  height: 1px;
  left: 14px;
  position: absolute;
  top: 4177px;
  width: 9px;
}

.skillbook-case-study .frame-191 {
  background-color: #f4fdf8;
  border-radius: 5.38px;
  box-shadow: 0px 2.69px 10.77px #0000000f;
  height: 72px;
  left: 676px;
  overflow: hidden;
  position: absolute;
  top: 758px;
  width: 238px;
}

.skillbook-case-study .group-50 {
  height: 36px;
  left: 9px;
  position: relative;
  top: 18px;
  width: 219px;
}

.skillbook-case-study .frame-192 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 6.73px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .frame-193 {
  align-items: center;
  background-color: #fb5741;
  border-radius: 5.38px;
  display: flex;
  gap: 6.73px;
  height: 35.67px;
  justify-content: center;
  padding: 10.77px 59.22px 10.77px 56.53px;
  position: relative;
  width: 219.39px;
}

.skillbook-case-study .text-wrapper-130 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 13.5px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  margin-left: -50.18px;
  margin-right: -50.18px;
  margin-top: -2.28px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .home-page-final {
  height: 1147px;
  left: 118px;
  position: absolute;
  top: 101px;
  width: 998px;
}

.skillbook-case-study .frame-194 {
  align-items: flex-start;
  background-color: #000000;
  display: flex;
  flex-direction: column;
  height: 592px;
  justify-content: space-between;
  left: 0;
  padding: 120px 120px 80px;
  position: absolute;
  top: 0;
  width: 1920px;
}

.skillbook-case-study .heading-wrapper {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  gap: 260px;
  height: 328px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .heading {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 32px;
  position: relative;
  width: 1181px;
}

.skillbook-case-study .text-wrapper-131 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 96px;
  font-weight: 400;
  letter-spacing: -0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .digital-branding-web {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  letter-spacing: -0.16px;
  line-height: 48px;
  position: relative;
  width: 823px;
}

.skillbook-case-study .group-51 {
  height: 108px;
  margin-top: -44px;
  position: relative;
  width: 166px;
}

.skillbook-case-study .UIX-wrapper {
  align-items: flex-start;
  display: inline-flex;
  gap: 16px;
  left: 0;
  position: absolute;
  top: 52px;
}

.skillbook-case-study .UIX {
  height: 56px;
  position: relative;
  width: 164px;
}

.skillbook-case-study .frame-195 {
  align-items: flex-start;
  background-color: #ffffff1f;
  border-radius: 28px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 4px 16px 4px 4px;
  position: relative;
}

.skillbook-case-study .frame-196 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.skillbook-case-study .group-52 {
  background-color: #f7f7f7;
  border-radius: 24px;
  height: 48px;
  position: relative;
  width: 48px;
}

.skillbook-case-study .overlap-group-13 {
  height: 22px;
  left: 13px;
  position: relative;
  top: 13px;
  width: 22px;
}

.skillbook-case-study .line-12 {
  height: 22px;
  left: 9px;
  position: absolute;
  top: 0;
  width: 5px;
}

.skillbook-case-study .line-13 {
  height: 4px;
  left: 0;
  position: absolute;
  top: 9px;
  width: 22px;
}

.skillbook-case-study .text-wrapper-132 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Display Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.46px;
  line-height: 32.9px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-133 {
  color: #ffffff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  height: 40px;
  left: 0;
  letter-spacing: -0.39px;
  line-height: 40px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.skillbook-case-study .overlap-13 {
  height: 2878px;
  left: 0;
  position: absolute;
  top: 2352px;
  width: 1920px;
}

.skillbook-case-study .rectangle-14 {
  background-color: #0e3b65;
  height: 1149px;
  left: 0;
  position: absolute;
  top: 1729px;
  width: 1920px;
}

.skillbook-case-study .home-page-final-2 {
  height: 2428px;
  left: 143px;
  position: absolute;
  top: 0;
  width: 1634px;
}

.skillbook-case-study .frame-197 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 33px;
  left: 141px;
  position: absolute;
  top: 2553px;
}

.skillbook-case-study .text-wrapper-134 {
  color: #fcfcfc;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .the-objective-of-the {
  color: #d9d9d9;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: -0.3px;
  line-height: 50.4px;
  position: relative;
  width: 1634px;
}

.skillbook-case-study .element-4 {
  height: 1218px;
  left: 0;
  position: absolute;
  top: 5696px;
  width: 1920px;
}

.skillbook-case-study .image{
  position: absolute;
  left: 0;
  width: 1920px;
  top: 6870px;
  height: 2873px;
}

.skillbook-case-study .overlap-14 {
  height: 37px;
  left: 120px;
  position: absolute;
  top: -86px;
  width: 222px;
}

.skillbook-case-study .text-wrapper-135 {
  color: #2f2f2f;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.skillbook-case-study .frame-198 {
  height: 1180px;
  left: 1024px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 896px;
}

.skillbook-case-study .overlap-group-14 {
  background-color: #e3edf7;
  border-radius: 2px;
  height: 1180px;
  position: relative;
  width: 1020px;
}

.skillbook-case-study .overlap-15 {
  height: 769px;
  left: 156px;
  position: absolute;
  top: 89px;
  width: 651px;
}

.skillbook-case-study .text-wrapper-136 {
  color: #0d0d0d;
  font-family: "Manrope", Helvetica;
  font-size: 549px;
  font-weight: 400;
  left: 0;
  letter-spacing: -2.75px;
  line-height: 768.6px;
  position: absolute;
  top: 0;
  white-space: nowrap;
}

.skillbook-case-study .text-wrapper-137 {
  color: #0d0d0d;
  font-family: "Public Sans", Helvetica;
  font-size: 32px;
  font-weight: 400;
  left: 9px;
  letter-spacing: -0.16px;
  line-height: 44.8px;
  position: absolute;
  top: 39px;
  white-space: nowrap;
}

.skillbook-case-study .text-wrapper-138 {
  color: #0d0d0d;
  font-family: "Manrope", Helvetica;
  font-size: 48px;
  font-weight: 300;
  left: 156px;
  letter-spacing: 0;
  line-height: 67.2px;
  position: absolute;
  top: 858px;
  width: 693px;
}

.skillbook-case-study .frame-199 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  height: 1180px;
  left: 0;
  position: absolute;
  top: 0;
  width: 960px;
}

.skillbook-case-study .frame-200 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 769px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-201 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #fb5741;
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: flex-end;
  padding: 80px 64px;
  position: relative;
  width: 640px;
}

.skillbook-case-study .frame-202 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  justify-content: flex-end;
  position: relative;
}

.skillbook-case-study .text-wrapper-139 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-203 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 32px;
  position: relative;
}

.skillbook-case-study .text-wrapper-140 {
  color: var(--core-colorswhite);
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-204 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffedd9;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 80px 64px;
  position: relative;
}

.skillbook-case-study .text-wrapper-141 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-142 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .frame-205 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  height: 411px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-206 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #0e3b65;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.skillbook-case-study .frame-207 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #0d0d0d;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.skillbook-case-study .frame-208 {
  align-items: flex-start;
  align-self: stretch;
  background-color: #f4fdf8;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 32px;
  justify-content: flex-end;
  padding: 64px;
  position: relative;
}

.skillbook-case-study .csinc-wrapper {
  height: 1317px;
  left: -10px;
  position: absolute;
  top: 11420px;
  width: 1922px;
}

.skillbook-case-study .csinc {
  height: 1317px;
  left: 10px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1912px;
}

.skillbook-case-study .group-53 {
  height: 540px;
  left: -4px;
  position: absolute;
  top: 14349px;
  width: 1920px;
}

.skillbook-case-study .mask-group-wrapper {
  background-image: url(../../../static/img/rectangle-122.svg);
  background-size: 100% 100%;
  height: 540px;
  left: 4px;
  position: relative;
  width: 1916px;
}

.skillbook-case-study .mask-group {
  height: 540px;
  left: 0;
  position: absolute;
  top: 0;
  width: 1916px;
}

.skillbook-case-study .frame-209 {
  align-items: flex-start;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  gap: 56px;
  left: -4px;
  padding: 100px 120px;
  position: absolute;
  top: 13858px;
  width: 1919px;
}

.skillbook-case-study .element-s-efforts {
  align-self: stretch;
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 40px;
  font-weight: 400;
  letter-spacing: -0.8px;
  line-height: 56px;
  margin-top: -1px;
  position: relative;
}

.skillbook-case-study .frame-210 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.skillbook-case-study .frame-211 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 24px;
  position: relative;
}

.skillbook-case-study .frame-212 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .text-wrapper-143 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 28px;
  font-weight: 500;
  letter-spacing: -0.56px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-144 {
  color: #979797;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .text-wrapper-145 {
  color: #0d99ff;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.48px;
  line-height: normal;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

.skillbook-case-study .oqag {
  height: 1121px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 12737px;
  width: 1920px;
}

.skillbook-case-study .overlap-16 {
  height: 1618px;
  left: 0;
  position: absolute;
  top: 9729px;
  width: 1921px;
}

.skillbook-case-study .flying-two-side {
  height: 960px;
  left: 1px;
  position: absolute;
  top: 632px;
  width: 1102px;
}

.skillbook-case-study .element-5 {
  height: 984px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 634px;
  width: 1920px;
}

.skillbook-case-study .frame-213 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
}

.skillbook-case-study .rectangle-15 {
  background-color: #ffedd9;
  height: 633px;
  position: relative;
  width: 1921px;
}

.skillbook-case-study .frame-214 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 55px;
  left: 127px;
  position: absolute;
  top: 103px;
}

.skillbook-case-study .frame-215 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 149px;
}

.skillbook-case-study .frame-216 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.skillbook-case-study .text-wrapper-146 {
  align-self: stretch;
  color: #676768;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33.6px;
  margin-top: -1px;
  position: relative;
}

.skillbook-case-study .frame-217 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 224px;
  position: relative;
}

.skillbook-case-study .frame-218 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 52px;
  position: relative;
}

.skillbook-case-study .help-users-make {
  color: #676768;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33.6px;
  margin-top: -1px;
  position: relative;
  width: 700px;
}

.skillbook-case-study .text-wrapper-147 {
  color: #676768;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  height: 116px;
  letter-spacing: 0;
  line-height: 33.6px;
  position: relative;
  width: 721px;
}

.skillbook-case-study .frame-219 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 82px;
  position: relative;
}

.skillbook-case-study .address-the-question {
  color: #676768;
  font-family: "Neue Haas Grotesk Display Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 33.6px;
  margin-top: -1px;
  position: relative;
  width: 698px;
}

.skillbook-case-study .frame-220 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  left: 0;
  position: absolute;
  top: 5235px;
}

.skillbook-case-study .rectangle-16 {
  background-color: #f4fdf8;
  height: 454px;
  position: relative;
  width: 1920px;
}

.skillbook-case-study .frame-221 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  gap: 29px;
  left: 136px;
  position: absolute;
  top: 97px;
}

.skillbook-case-study .text-wrapper-148 {
  color: #000000;
  font-family: "Neue Haas Grotesk Text Pro-75Bold", Helvetica;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.3px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.skillbook-case-study .frame-222 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 145px;
  justify-content: center;
  position: relative;
}

.skillbook-case-study .name-michael-age {
  color: #0e3b65;
  font-family: "Neue Haas Grotesk Text Pro-65Medium", Helvetica;
  font-size: 36px;
  font-weight: 500;
  letter-spacing: -0.24px;
  line-height: 57.6px;
  position: relative;
  width: 552px;
}

.skillbook-case-study .michael-is-an {
  color: #0e3b65;
  font-family: "Neue Haas Grotesk Text Pro-55Roman", Helvetica;
  font-size: 24px;
  font-weight: 400;
  letter-spacing: -0.24px;
  line-height: 38.4px;
  margin-top: -1px;
  position: relative;
  width: 957px;
}
