.component-2 {
  align-items: flex-start;
  background-color: #ffffff;
  border-radius: 8px;
  display: inline-flex;
  gap: 10px;
  padding: 10px 12px;
  position: relative;
}

.component-2:hover {
  background-color: #f4fdf8;
}

.component-2 .product-owners {
  color: #27282c;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: -0.45px;
  line-height: 20.8px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.component-2:hover .product-owners {
  color: #fb5741;
}
