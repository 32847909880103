.property-frame-wrapper .group-3 {
  position: relative;
}

.property-frame-wrapper .overlap-group-5 {
  background-image: url(../../../static/img/group-10.png);
  background-size: 100% 100%;
  height: 171px;
  position: relative;
  width: 152px;
}

.property-frame-wrapper .text-wrapper-5 {
  color: #ffffff;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  height: 16px;
  left: 58px;
  letter-spacing: 0;
  line-height: 22.4px;
  position: absolute;
  top: 61px;
  white-space: nowrap;
  width: 38px;
}

.property-frame-wrapper .salary-male {
  height: 24px;
  left: 58px;
  position: absolute;
  top: 29px;
  width: 24px;
}

.property-frame-wrapper .group-4 {
  height: 171px;
  left: 14px;
  position: absolute;
  top: 56px;
  width: 154px;
}

.property-frame-wrapper .group-5 {
  height: 83px;
  left: 0;
  position: absolute;
  top: 0;
  width: 152px;
}

.property-frame-wrapper.property-1-0-frame-40245 {
  height: 227px;
  width: 166px;
}

.property-frame-wrapper.property-1-0-frame-40246 {
  height: 195px;
  width: 152px;
}

.property-frame-wrapper.property-1-0-frame-40245 .group-3 {
  height: 227px;
  width: 168px;
}

.property-frame-wrapper.property-1-0-frame-40246 .group-3 {
  height: 171px;
  top: 24px;
  width: 154px;
}
