.component-24 {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 60px;
  position: relative;
}

.component-24 .frame-wrapper {
  align-items: flex-start;
  background-color: #f7f7f7;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 40px 80px;
  position: relative;
}

.component-24 .frame {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  gap: 150px;
  position: relative;
  width: 1280px;
}

.component-24 .frame-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.component-24 .text-wrapper-7 {
  color: #0b0b0b;
  font-family: var(--h2-subtext-font-family);
  font-size: var(--h2-subtext-font-size);
  font-style: var(--h2-subtext-font-style);
  font-weight: var(--h2-subtext-font-weight);
  letter-spacing: var(--h2-subtext-letter-spacing);
  line-height: var(--h2-subtext-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.component-24 .frame-3 {
  height: 262px;
  position: relative;
  width: 837px;
}

.component-24 .frame-4 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 0;
}

.component-24 .frame-5 {
  align-items: flex-start;
  border-radius: 2px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 3px;
  position: relative;
}

.component-24 .vector-wrapper {
  background-color: #fb5741;
  border-radius: 6.55px;
  height: 24px;
  position: relative;
  width: 24px;
}

.component-24 .vector-2 {
  height: 7px;
  left: 7px;
  position: absolute;
  top: 9px;
  width: 10px;
}

.component-24 .p {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.07px;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  width: 326px;
}

.component-24 .frame-6 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  height: 79px;
  justify-content: center;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 95px;
}

.component-24 .text-wrapper-8 {
  color: #000000;
  font-family: "Inter", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.07px;
  line-height: 21px;
  position: relative;
  width: 326px;
}

.component-24 .frame-7 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 0;
  padding: 8px;
  position: absolute;
  top: 190px;
}

.component-24 .frame-8 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  height: 79px;
  justify-content: center;
  left: 407px;
  padding: 8px;
  position: absolute;
  top: 0;
}

.component-24 .frame-9 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 407px;
  padding: 8px;
  position: absolute;
  top: 95px;
}

.component-24 .frame-10 {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  left: 407px;
  padding: 8px;
  position: absolute;
  top: 190px;
}

.component-24 .element {
  object-fit: cover;
}

.component-24 .frame-40442 {
  height: 253.38px;
  position: relative;
  width: 253.38px;
}

.component-24 .frame-40443 {
  height: 158px;
  left: 987px;
  position: absolute;
  top: -40px;
  width: 253px;
}

.component-24 .frame-40441 {
  height: 167px;
  left: 987px;
  position: absolute;
  top: 195px;
  width: 253px;
}
